/**
 * Certification component handles the certification process of applications.
 * It fetches and displays ownership information, allows users to certify relationships,
 * and provides various functionalities related to certification.
 *
 * @component
 * @param {Object} props - The properties passed to the component.
 * @param {Function} props.setProgress - Function to set the progress of an operation.
 *
 * @returns {JSX.Element} The rendered Certification component.
 *
 * @example
 * <Certification setProgress={setProgress} />
 *
 * @typedef {Object} OwnershipData
 * @property {Object} managed_by - Managed by information.
 * @property {Object} owned_by - Owned by information.
 * @property {Object} u_engineering_manager - Engineering manager information.
 * @property {Object} u_sr_engineering_manager - Senior engineering manager information.
 * @property {Object} u_engineering_director - Engineering director information.
 *
 * @typedef {Object} TableContext
 * @property {Object} graphData - Graph data information.
 * @property {Function} setapplicationName - Function to set the application name.
 * @property {boolean} isciadmingrp - Indicates if the user is in the admin group.
 * @property {Function} setisciadmingrp - Function to set the admin group status.
 * @property {string} certifyapplicationName - Name of the application to certify.
 * @property {Function} setcertifyapplicationName - Function to set the application name to certify.
 * @property {Function} setautosearch - Function to set the auto search status.
 * @property {OwnershipData} ownershipdata - Ownership data of the application.
 * @property {Function} setownershipdata - Function to set the ownership data.
 * @property {boolean} disableCertify1 - Indicates if the certify button is disabled.
 * @property {Function} setDisableCertify1 - Function to set the disable status of the certify button.
 * @property {boolean} certificationSuccessful1 - Indicates if the certification was successful.
 * @property {Function} setCertificationSuccessful1 - Function to set the certification success status.
 * @property {string} ownershipTooltip - Tooltip for ownership information.
 * @property {Function} setownershipTooltip - Function to set the ownership tooltip.
 * @property {number} certifypercentage - Certification percentage.
 * @property {Function} setcertifypercentage - Function to set the certification percentage.
 * @property {boolean} showTooltip1 - Indicates if the first tooltip should be shown.
 * @property {Function} setShowTooltip1 - Function to set the visibility of the first tooltip.
 * @property {string} appenv - Application environment.
 * @property {Function} setappenv - Function to set the application environment.
 * @property {boolean} relationcertified - Indicates if the relationship is certified.
 * @property {Function} setrelationcertified - Function to set the relationship certification status.
 * @property {boolean} ownershipcertified - Indicates if the ownership is certified.
 * @property {Function} setownershipcertified - Function to set the ownership certification status.
 * @property {boolean} autosearch1 - Indicates if auto search is enabled.
 * @property {Function} setautosearch1 - Function to set the auto search status.
 * @property {Function} fetchOwnership - Function to fetch ownership information.
 * @property {boolean} showTooltip2 - Indicates if the second tooltip should be shown.
 * @property {Function} setShowTooltip2 - Function to set the visibility of the second tooltip.
 * @property {Function} setRelationshipCategorySelected - Function to set the selected relationship category.
 * @property {string} relationshipCategory - Selected relationship category.
 * @property {Function} fetchWithTimeout - Function to fetch data with a timeout.
 * @property {boolean} disableCertify1apptoapp - Indicates if the app-to-app certify button is disabled.
 * @property {Function} setDisableCertify1apptoapp - Function to set the disable status of the app-to-app certify button.
 * @property {boolean} showTooltip2apptoapp - Indicates if the second app-to-app tooltip should be shown.
 * @property {Function} setShowTooltip2apptoapp - Function to set the visibility of the second app-to-app tooltip.
 * @property {boolean} showTooltip1apptoapp - Indicates if the first app-to-app tooltip should be shown.
 * @property {Function} setShowTooltip1apptoapp - Function to set the visibility of the first app-to-app tooltip.
 * @property {boolean} relationcertifiedapptoapp - Indicates if the app-to-app relationship is certified.
 * @property {Function} setrelationcertifiedapptoapp - Function to set the app-to-app relationship certification status.
 * @property {Object} selectedSuggestion - Selected suggestion information.
 * @property {Function} setSelectedSuggestion - Function to set the selected suggestion.
 * @property {Object} macDetails - MAC details information.
 * @property {Function} setMacDetails - Function to set the MAC details.
 * @property {string} token - Authentication token.
 */
import React, { useEffect, useContext, useState, useRef } from "react";
import "./Certification.css";
import {
  Button,
  Icon,
  LoadingIndicator,
  Accordian,
} from "@anchor/react-components";
import { Tooltip } from "react-tooltip";
import { TableContext } from "../context";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { FcApproval } from "react-icons/fc";
import { FaExclamationCircle, FaCheckCircle } from "react-icons/fa";
import { FaUserAltSlash } from "react-icons/fa";
import { set } from "react-hook-form";

function Certification(props) {
  const [readOnly, setReadOnly] = useState(false);
  const [supportGroupreadOnly, setSupportGroupreadOnly] = useState(true);
  const [supportGroupreadOnly2, setSupportGroupreadOnly2] = useState(true);

  const [showbuttons, setshowbuttons] = useState(false);
  const [showSupportGroupButtons, setShowSupportGroupButtons] = useState(false);
  const [showSupportGroupButtons2, setShowSupportGroupButtons2] =
    useState(false);

  const [isViewOpen, setIsViewOpen] = useState(false);
  const [isViewOpen1, setIsViewOpen1] = useState(false);
  const [isSupportGroupViewOpen, setIsSupportGroupViewOpen] = useState(false);

  const [isLoading, setIsLoading] = useState(false);
  const inputRef = useRef(null);
  const [preventEffect, setPreventEffect] = useState(false);

  
  const [lastUpdatedField, setLastUpdatedField] = useState(null);
  const operationalStatusOptions = {
    "Operational": "1",
    "Non-Operational": "2",
    "Repair in Progress": "3",
    "DR Standby": "4",
    Ready: "5",
    Retired: "6",
  };

  const [dropdownStates, setDropdownStates] = useState({
    owned_by: true,
    managed_by: true,
    u_engineering_manager: true,
    u_sr_engineering_manager: true,
    u_engineering_director: true,
    change_control: true,
    support_group: true,
    u_service_request_support_group: true,
    u_support_group_l2: true,
    u_support_group_l3: true,
    u_support_group_l4: true,
    vendor: true,
  });
  const navigate = useNavigate();

  const {
    graphData,
    setapplicationName,
    isciadmingrp,
    setisciadmingrp,
    certifyapplicationName,
    setcertifyapplicationName,
    setautosearch,
    ownershipdata,
    setownershipdata,
    disableCertify1,
    setDisableCertify1,
    certificationSuccessful1,
    setCertificationSuccessful1,
    searchbarName,
    setSearchbarName,
    arrowStatus,
    setArrowStatus,
    ownershipTooltip,
    setownershipTooltip,
    certifypercentage,
    setcertifypercentage,
    showTooltip1,
    setShowTooltip1,
    appenv,
    setappenv,
    relationcertified,
    setrelationcertified,
    ownershipcertified,
    setownershipcertified,
    autosearch1,
    setautosearch1,
    fetchOwnership,
    showTooltip2,
    setShowTooltip2,
    setRelationshipCategorySelected,
    relationshipCategory,
    fetchWithTimeout,
    disableCertify1apptoapp,
    setDisableCertify1apptoapp,
    showTooltip2apptoapp,
    setShowTooltip2apptoapp,
    showTooltip1apptoapp,
    setShowTooltip1apptoapp,
    relationcertifiedapptoapp,
    setrelationcertifiedapptoapp,
    selectedSuggestion,
    setSelectedSuggestion,
    macDetails,
    setMacDetails,
    setSuggestionSelected,
    token,
    showSaaS1,
    setShowSaaS1,
    disableSaaS1,
    setDisableSaaS1,
    showSaaSTooltip1,
    setShowSaaSTooltip1,
    markedAsSaaS,
    setMarkedAsSaaS,
  } = useContext(TableContext);
  const [tempData, setTempData] = useState({ ...ownershipdata });
  const [supportGroupUpdatedData, setSupportGroupUpdatedData] = useState(() => {
    const {
      vendor,
      u_environment,
      u_ci_admin_group,
      operational_status,
      change_control,
      support_group,
      u_service_request_support_group,
      u_support_group_l2,
      u_support_group_l3,
      u_support_group_l4,
      sys_id,
      u_application_type,
    } = ownershipdata;
    return {
      vendor,
      u_environment,
      u_ci_admin_group,
      operational_status,
      change_control,
      support_group,
      u_service_request_support_group,
      u_support_group_l2,
      u_support_group_l3,
      u_support_group_l4,
      sys_id,
      u_application_type,
    };
  });
  const [editable, setEditable] = useState(false);
  const [isRotated, setIsRotated] = useState(false);

  useEffect(() => {
    if (!supportGroupreadOnly) {
      const timeout = setTimeout(() => {
        handleApprovalGroupChange();
        setLastUpdatedField(null);
      }, 500);
      return () => clearTimeout(timeout);
    }
  }, [supportGroupUpdatedData?.change_control]);

  const handleApprovalGroupChange = async () => {
    if (!lastUpdatedField) return;
    if (!supportGroupreadOnly) {
      const category = lastUpdatedField;
      const searchbox = document.getElementById("search-icon");
      //console.log(searchbox);
      // setDropdownStates({
      //   ...dropdownStates,
      //   [e.target.id]: false,
      // });

      //console.log("clicked", e.target.id);
      const elements = document.getElementsByName(category);
      //console.log(elements);
      const searchBox = document.getElementsByName(category)[0];
      const suggestionBox = document.getElementsByName(category)[2];
      const searchicon = document.getElementsByName(category)[1];
      const elements1 = document.querySelectorAll(".suggestion-container3");
      //console.log(elements1);
      elements1.forEach((element) => {
        element.style.display = "none";
      });
      const elements2 = document.querySelectorAll(".search-bar");
      //console.log(elements2);
      elements2.forEach((element) => {
        element.style.borderBottomLeftRadius = "5px";
      });

      const elements3 = document.querySelectorAll(".search-icon");
      //console.log(elements3);
      elements3.forEach((element) => {
        element.style.borderBottomRightRadius = "5px";
      });

      const field = "change_control";
      const searchTerm = supportGroupUpdatedData[category]?.displayValue;

      console.log(searchTerm.length);
      if (searchTerm.length == 0) {
        suggestionBox.style.display = "none";
        suggestionBox.innerHTML = "";
        searchBox.style.borderBottomLeftRadius = "5px";
        searchicon.style.borderBottomRightRadius = "5px";
      }
      if (searchTerm.length >= 3) {
        console.log(searchTerm);

        suggestionBox.style.display = "block";

        setDropdownStates((prevState) => {
          const newState = {};
          for (let key in prevState) {
            if (key === category) {
              newState[key] = false;
            } else {
              newState[key] = true;
            }
          }
          return newState;
        });
        searchBox.style.borderBottomLeftRadius = "0%";
        searchicon.style.borderBottomRightRadius = "0%";
        suggestionBox.innerHTML = "<div>Loading...</div>";
        await fetch(
          `${process.env.REACT_APP_BACKEND_URI}/suggestapprovalgroupsupportgroup`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
            body: JSON.stringify({
              group_name: searchTerm,
              group_type: "approval_group",
            }),
          }
        )
          .then((response) => response.json())
          .then((data) => {
            // Clear previous suggestions
            suggestionBox.innerHTML = "";
            if (searchTerm.trim() === "") {
              suggestionBox.innerHTML = "";
              suggestionBox.style.display = "none";
              return;
            }
            // Display a maximum of 20 suggestions
            // const maxSuggestions = 20;
            for (let i = 0; i < data.length; i++) {
              const suggestionItem = document.createElement("div");
              suggestionItem.classList.add("suggestion-item");
              // suggestionItem.textContent = data[i];
              // suggestionItem.innerHTML = "";
              //console.log(data[i]);
              //console.log(data[i].name);

              suggestionItem.innerHTML = ` ${
                data[i].name == undefined ? "No results" : data[i].name
              } `;
              if (data?.message == "Record(s) not found") {
                suggestionItem.innerHTML = ` No Results `;
                console.log("No results");
              }
              if (data[i].name != undefined) {
                suggestionItem.addEventListener("click", async function () {
                  setSupportGroupUpdatedData({
                    ...supportGroupUpdatedData,
                    [field]: {
                      displayValue: data[i].name,
                      previousvalue: ownershipdata[field]?.displayValue,
                      value: data[i].sys_id,
                    },
                  });
                  searchBox.style.borderBottomLeftRadius = "5px";
                  searchicon.style.borderBottomRightRadius = "5px";
                  setDropdownStates((prevState) => {
                    const newState = {};
                    for (let key in prevState) {
                      newState[key] = true;
                    }
                    return newState;
                  });
                  suggestionBox.style.display = "none"; // Hide the suggestion box on click
                });
              }
              suggestionBox.appendChild(suggestionItem);
            }
            if (data.length > 0) {
              suggestionBox.style.display = "block";
              searchBox.style.borderBottomLeftRadius = "0%";
              searchicon.style.borderBottomRightRadius = "0%"; // Show the suggestion box
            } else {
              setDropdownStates((prevState) => {
                const newState = {};
                for (let key in prevState) {
                  if (key === category) {
                    newState[key] = true;
                  } else {
                    newState[key] = false;
                  }
                }
                return newState;
              });
              suggestionBox.innerHTML = "";
              suggestionBox.style.display = "none";
              searchBox.style.borderBottomLeftRadius = "5px";
              searchicon.style.borderBottomRightRadius = "5px"; // Hide if there are no suggestions
            }
          })
          .catch((error) => {
            console.error("Error fetching data:", error);
          });
      }
      if (searchTerm.length == 0) {
        setDropdownStates({
          ...dropdownStates,
          [category]: true,
        });
        console.log("No data");
        while (suggestionBox.firstChild) {
          suggestionBox.removeChild(suggestionBox.firstChild);
        }
        suggestionBox.style.display = "none";
        searchBox.style.borderBottomLeftRadius = "5px";
        searchicon.style.borderBottomRightRadius = "5px"; // Hide if the input length is less than 3
      }
    }
  };

  useEffect(() => {
    if (!supportGroupreadOnly) {
      const timeout = setTimeout(() => {
        handleVendorChange();
        setLastUpdatedField(null);
      }, 500);
      return () => clearTimeout(timeout);
    }
  }, [supportGroupUpdatedData?.vendor]);

  const handleVendorChange = async () => {
    if (!lastUpdatedField) return;
    if (!supportGroupreadOnly) {
      const category = lastUpdatedField;
      const searchbox = document.getElementById("search-icon");
      //console.log(searchbox);
      // setDropdownStates({
      //   ...dropdownStates,
      //   [e.target.id]: false,
      // });

      //console.log("clicked", e.target.id);
      const elements = document.getElementsByName(category);
      //console.log(elements);
      const searchBox = document.getElementsByName(category)[0];
      const suggestionBox = document.getElementsByName(category)[2];
      const searchicon = document.getElementsByName(category)[1];
      const elements1 = document.querySelectorAll(".suggestion-container3");
      //console.log(elements1);
      elements1.forEach((element) => {
        element.style.display = "none";
      });
      const elements2 = document.querySelectorAll(".search-bar");
      //console.log(elements2);
      elements2.forEach((element) => {
        element.style.borderBottomLeftRadius = "5px";
      });

      const elements3 = document.querySelectorAll(".search-icon");
      //console.log(elements3);
      elements3.forEach((element) => {
        element.style.borderBottomRightRadius = "5px";
      });

      const field = "vendor";
      const searchTerm = supportGroupUpdatedData[category]?.displayValue;

      console.log(searchTerm.length);
      if (searchTerm.length == 0) {
        suggestionBox.style.display = "none";
        suggestionBox.innerHTML = "";
        searchBox.style.borderBottomLeftRadius = "5px";
        searchicon.style.borderBottomRightRadius = "5px";
      }
      if (searchTerm.length >= 3) {
        console.log(searchTerm);

        suggestionBox.style.display = "block";

        setDropdownStates((prevState) => {
          const newState = {};
          for (let key in prevState) {
            if (key === category) {
              newState[key] = false;
            } else {
              newState[key] = true;
            }
          }
          return newState;
        });
        searchBox.style.borderBottomLeftRadius = "0%";
        searchicon.style.borderBottomRightRadius = "0%";
        suggestionBox.innerHTML = "<div>Loading...</div>";
        await fetch(
          `${process.env.REACT_APP_BACKEND_URI}/getvendorsuggestions`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
            body: JSON.stringify({
              vendor_name: searchTerm,
            }),
          }
        )
          .then((response) => response.json())
          .then((data) => {
            // Clear previous suggestions
            suggestionBox.innerHTML = "";
            if (searchTerm.trim() === "") {
              suggestionBox.innerHTML = "";
              suggestionBox.style.display = "none";
              return;
            }
            // Display a maximum of 20 suggestions
            // const maxSuggestions = 20;
            for (let i = 0; i < data.length; i++) {
              const suggestionItem = document.createElement("div");
              suggestionItem.classList.add("suggestion-item");
              // suggestionItem.textContent = data[i];
              // suggestionItem.innerHTML = "";
              //console.log(data[i]);
              //console.log(data[i].name);

              suggestionItem.innerHTML = ` ${
                data[i].name == undefined ? "No results" : data[i].name
              } `;
              if (data?.message == "Record(s) not found") {
                suggestionItem.innerHTML = ` No Results `;
                console.log("No results");
              }
              if (data[i].name != undefined) {
                suggestionItem.addEventListener("click", async function () {
                  setSupportGroupUpdatedData({
                    ...supportGroupUpdatedData,
                    [field]: {
                      displayValue: data[i].name,
                      previousvalue: ownershipdata[field]?.displayValue,
                      value: data[i].sys_id,
                    },
                  });
                  searchBox.style.borderBottomLeftRadius = "5px";
                  searchicon.style.borderBottomRightRadius = "5px";
                  setDropdownStates((prevState) => {
                    const newState = {};
                    for (let key in prevState) {
                      newState[key] = true;
                    }
                    return newState;
                  });
                  suggestionBox.style.display = "none"; // Hide the suggestion box on click
                });
              }
              suggestionBox.appendChild(suggestionItem);
            }
            if (data.length > 0) {
              suggestionBox.style.display = "block";
              searchBox.style.borderBottomLeftRadius = "0%";
              searchicon.style.borderBottomRightRadius = "0%"; // Show the suggestion box
            } else {
              setDropdownStates((prevState) => {
                const newState = {};
                for (let key in prevState) {
                  if (key === category) {
                    newState[key] = true;
                  } else {
                    newState[key] = false;
                  }
                }
                return newState;
              });
              suggestionBox.innerHTML = "";
              suggestionBox.style.display = "none";
              searchBox.style.borderBottomLeftRadius = "5px";
              searchicon.style.borderBottomRightRadius = "5px"; // Hide if there are no suggestions
            }
          })
          .catch((error) => {
            console.error("Error fetching data:", error);
          });
      }
      if (searchTerm.length == 0) {
        setDropdownStates({
          ...dropdownStates,
          [category]: true,
        });
        console.log("No data");
        while (suggestionBox.firstChild) {
          suggestionBox.removeChild(suggestionBox.firstChild);
        }
        suggestionBox.style.display = "none";
        searchBox.style.borderBottomLeftRadius = "5px";
        searchicon.style.borderBottomRightRadius = "5px"; // Hide if the input length is less than 3
      }
    }
  };

  useEffect(() => {
    if (!supportGroupreadOnly2) {
      const timeout = setTimeout(() => {
        handleSupportGroupChange1();
        setLastUpdatedField(null);
      }, 500);
      return () => clearTimeout(timeout);
    }
  }, [
    supportGroupUpdatedData?.support_group,
    supportGroupUpdatedData?.u_service_request_support_group,
    supportGroupUpdatedData?.u_support_group_l2,
    supportGroupUpdatedData?.u_support_group_l3,
    supportGroupUpdatedData?.u_support_group_l4,
  ]);

  const handleSupportGroupChange1 = async () => {
    if (!lastUpdatedField) return;
    if (!supportGroupreadOnly2) {
      const category = lastUpdatedField;
      const searchbox = document.getElementById("search-icon");
      //console.log(searchbox);
      // setDropdownStates({
      //   ...dropdownStates,
      //   [e.target.id]: false,
      // });

      //console.log("clicked", e.target.id);
      const elements = document.getElementsByName(category);
      //console.log(elements);
      const searchBox = document.getElementsByName(category)[0];
      const suggestionBox = document.getElementsByName(category)[2];
      const searchicon = document.getElementsByName(category)[1];
      const elements1 = document.querySelectorAll(".suggestion-container3");
      //console.log(elements1);
      elements1.forEach((element) => {
        element.style.display = "none";
      });
      const elements2 = document.querySelectorAll(".search-bar");
      //console.log(elements2);
      elements2.forEach((element) => {
        element.style.borderBottomLeftRadius = "5px";
      });

      const elements3 = document.querySelectorAll(".search-icon");
      //console.log(elements3);
      elements3.forEach((element) => {
        element.style.borderBottomRightRadius = "5px";
      });

      const field = category;
      const searchTerm = supportGroupUpdatedData[category]?.displayValue;

      console.log(searchTerm.length);
      if (searchTerm.length == 0) {
        suggestionBox.style.display = "none";
        suggestionBox.innerHTML = "";
        searchBox.style.borderBottomLeftRadius = "5px";
        searchicon.style.borderBottomRightRadius = "5px";
      }
      if (searchTerm.length >= 3) {
        console.log(searchTerm);

        suggestionBox.style.display = "block";

        setDropdownStates((prevState) => {
          const newState = {};
          for (let key in prevState) {
            if (key === category) {
              newState[key] = false;
            } else {
              newState[key] = true;
            }
          }
          return newState;
        });
        searchBox.style.borderBottomLeftRadius = "0%";
        searchicon.style.borderBottomRightRadius = "0%";
        suggestionBox.innerHTML = "<div>Loading...</div>";
        await fetch(
          `${process.env.REACT_APP_BACKEND_URI}/suggestapprovalgroupsupportgroup`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
            body: JSON.stringify({
              group_name: searchTerm,
              group_type: "support_group",
            }),
          }
        )
          .then((response) => response.json())
          .then((data) => {
            // Clear previous suggestions
            suggestionBox.innerHTML = "";
            if (searchTerm.trim() === "") {
              suggestionBox.innerHTML = "";
              suggestionBox.style.display = "none";
              return;
            }
            // Display a maximum of 20 suggestions
            // const maxSuggestions = 20;
            for (let i = 0; i < data.length; i++) {
              const suggestionItem = document.createElement("div");
              suggestionItem.classList.add("suggestion-item");
              // suggestionItem.textContent = data[i];
              // suggestionItem.innerHTML = "";
              //console.log(data[i]);
              //console.log(data[i].name);

              suggestionItem.innerHTML = ` ${
                data[i].name == undefined ? "No results" : data[i].name
              } `;
              if (data?.message == "Record(s) not found") {
                suggestionItem.innerHTML = ` No Results `;
                console.log("No results");
              }
              if (data[i].name != undefined) {
                suggestionItem.addEventListener("click", async function () {
                  setSupportGroupUpdatedData({
                    ...supportGroupUpdatedData,
                    [field]: {
                      displayValue: data[i].name,
                      previousvalue: ownershipdata[field]?.displayValue,
                      value: data[i].sys_id,
                    },
                  });
                  searchBox.style.borderBottomLeftRadius = "5px";
                  searchicon.style.borderBottomRightRadius = "5px";
                  setDropdownStates((prevState) => {
                    const newState = {};
                    for (let key in prevState) {
                      newState[key] = true;
                    }
                    return newState;
                  });
                  suggestionBox.style.display = "none"; // Hide the suggestion box on click
                });
              }
              suggestionBox.appendChild(suggestionItem);
            }
            if (data.length > 0) {
              suggestionBox.style.display = "block";
              searchBox.style.borderBottomLeftRadius = "0%";
              searchicon.style.borderBottomRightRadius = "0%"; // Show the suggestion box
            } else {
              setDropdownStates((prevState) => {
                const newState = {};
                for (let key in prevState) {
                  if (key === category) {
                    newState[key] = true;
                  } else {
                    newState[key] = false;
                  }
                }
                return newState;
              });
              suggestionBox.innerHTML = "";
              suggestionBox.style.display = "none";
              searchBox.style.borderBottomLeftRadius = "5px";
              searchicon.style.borderBottomRightRadius = "5px"; // Hide if there are no suggestions
            }
          })
          .catch((error) => {
            console.error("Error fetching data:", error);
          });
      }
      if (searchTerm.length == 0) {
        setDropdownStates({
          ...dropdownStates,
          [category]: true,
        });
        console.log("No data");
        while (suggestionBox.firstChild) {
          suggestionBox.removeChild(suggestionBox.firstChild);
        }
        suggestionBox.style.display = "none";
        searchBox.style.borderBottomLeftRadius = "5px";
        searchicon.style.borderBottomRightRadius = "5px"; // Hide if the input length is less than 3
      }
    }
  };

  const handleAccordionclick = (e, accordionname, panelname) => {
    // //console.log("e",e.target)
    var acc = document.getElementsByClassName(accordionname);
    var i;
    acc[0].classList.toggle("active");
    var panel = document.getElementsByClassName(panelname)[0];
    if (panel.style.maxHeight) {
      panel.style.maxHeight = null;
      panel.style.marginTop = "0px";
    } else {
      panel.style.maxHeight = panel.scrollHeight + 30 + "px";
      panel.style.marginTop = "10px";
    }
  };
  async function getOwnershipInfo(application) {
    try {
      const response = await fetchWithTimeout(
        `${process.env.REACT_APP_BACKEND_URI}/getownershipinfo`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
          body: JSON.stringify({
            applicationName: application,
            graph_access_token: token,
          }),
        }
      );
      // props.setProgress(40);
      const result = await response.json();
      // //console.log("ownership data", result);
      // const data = {
      //   managed_by: { displayValue: "", value: "" },
      //   owned_by: { displayValue: "", value: "" },
      //   sys_id: "",
      //   u_engineering_manager: { displayValue: "", value: "" },
      //   u_sr_engineering_manager: { displayValue: "", value: "" },
      //   u_engineering_director: { displayValue: "", value: "" },
      // };
      // Object.keys(result).forEach((key) => {
      //   const value = result[key];
      //   if (key === "owned_by") {
      //     data.owned_by.name = value;
      //   } else if (key === "managed_by") {
      //     data.managed_by.name = value;
      //   } else if (key === "u_engineering_manager") {
      //     data.u_engineering_manager.name = value;
      //   } else if (key === "u_sr_engineering_manager") {
      //     data.u_sr_engineering_manager.name = value;
      //   } else if (key === "u_engineering_director") {
      //     data.u_engineering_director.name = value;
      //   } else if (key === "sys_id") {
      //     data.sys_id = value;
      //   }
      // });
      await setownershipdata(result);
      if (autosearch1) {
        // inputRef.current.click();
        //console.log(inputRef.current);
      }
      setReadOnly(false);

      //console.log("data", ownershipdata?.managed_by.displayValue);

      // props.setProgress(100);
    } catch (error) {
      if (error.message === "Timeout") {
        toast.error("Request Timed Out", {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
    }
  }

  const autoSearchRefresh = async () => {
    props.setProgress(0);
    await getOwnershipInfo(certifyapplicationName);
    props.setProgress(20);
    await showRelationshipCertifyButton(certifyapplicationName);
    await showAppToAppCertifyButton(certifyapplicationName);
    props.setProgress(40);
    await getCertifyTooltip(certifyapplicationName);
    props.setProgress(60);
    await getCertificationPercentage(certifyapplicationName);
    props.setProgress(80);
    await getMacDetails(certifyapplicationName);
    await setautosearch1(false);
    props.setProgress(100);
  };

  useEffect(() => {
    if (autosearch1) {
      autoSearchRefresh();
    }
  }, []);

  useEffect(() => {
    const values = [
      ownershipdata?.managed_by.displayValue,
      ownershipdata?.owned_by.displayValue,
      ownershipdata?.u_engineering_manager.displayValue,
      ownershipdata?.u_sr_engineering_manager.displayValue,
      ownershipdata?.u_engineering_director.displayValue,
    ];
    console.log("values", values);
    setisciadmingrp(values.includes(graphData?.displayName));
    // //console.log("isciadmingrp", isciadmingrp);
  }, [ownershipdata, graphData?.displayName]);


  
  const handleapplicationNameChange = async (event) => {
    setSearchbarName(event.target.value);
   
  
    // Clear suggestions if input is empty
   
  };

  const HandleSuggestionAppIdNames = async (selectedAppId, suggestionBox) => {
    // Fetch additional app data
    await fetchAdditionalAppData(selectedAppId, props);
  };

  // Helper function for fetching app-specific data
  const fetchAdditionalAppData = async (selectedItem, props) => {
    const searchBox = document.getElementById("search-bar2");
    const suggestionBox = document.getElementById("suggestion-container2");
    // const selectionButton = document.getElementById("suggestion-button");

    try {
      const response = await fetchWithTimeout(
        `${
          process.env.REACT_APP_BACKEND_URI
        }/getappidappsforcertify?applicationName=${encodeURIComponent(
          selectedItem
        )}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      const data = await response.json();
      suggestionBox.innerHTML = ""; // Clear previous suggestions

      if (data.length > 0) {
        // Show suggestions and adjust the search box style
        suggestionBox.style.display = "block";
        searchBox.style.borderBottomLeftRadius = "0%";
        searchBox.style.borderBottomRightRadius = "0%";

        for (let i = 0; i < data.length; i++) {
          const innerSuggestionItem = document.createElement("div");
          innerSuggestionItem.classList.add("suggestion-item");
          innerSuggestionItem.textContent = data[i];

          // Add event listener to each suggestion
          innerSuggestionItem.addEventListener("click", async function () {
            await setPreventEffect(true);
            await setSearchbarName(`${selectedItem} - ${data[i]}`);
            await setcertifyapplicationName(data[i]);
            setArrowStatus(true);
            suggestionBox.style.display = "none"; // Hide the suggestion box
            searchBox.style.borderBottomLeftRadius = "0.25rem";
            searchBox.style.borderBottomRightRadius = "0%";
            searchBox.style.borderTopRightRadius = "0%";
            // searchBox.style.borderRight = "none";
            await fetchGeneralAppData(data[i], props);
          });
          suggestionBox.appendChild(innerSuggestionItem);
        }
      } else {
        // Hide the suggestion box if there are no suggestions
        suggestionBox.style.display = "none";
        searchBox.style.borderBottomLeftRadius = "0.25rem";
        searchBox.style.borderBottomRightRadius = "0.25rem";
      }
    } catch (error) {
      console.error("Error fetching additional app data:", error);
    }
  };

  // Helper function for fetching general app data
  const fetchGeneralAppData = async (selectedItem, props) => {
    try {
      await executeProgressFunctions(selectedItem, props);
    } catch (error) {
      console.error("Error fetching general app data:", error);
    }
  };

  // Helper function to execute progress-related operations
  const executeProgressFunctions = async (appName, props) => {
    props.setProgress(10);
    await getOwnershipInfo(appName);
    await showRelationshipCertifyButton(appName);
    props.setProgress(60);
    await getCertificationPercentage(appName);
    await showAppToAppCertifyButton(appName);
    props.setProgress(80);
    await getCertifyTooltip(appName);
    await getMacDetails(appName);
    props.setProgress(100);
  };
  const handleAppToAppRelationshipCertify = async () => {
    props.setProgress(10);
    try {
      const response = await fetchWithTimeout(
        `${process.env.REACT_APP_BACKEND_URI}/certify`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
          body: JSON.stringify({
            applicationName: certifyapplicationName,
            logged_in_user: graphData?.displayName,
            relationship_category: "app_to_app",
          }),
        }
      );
      const data = await response.json();
      props.setProgress(50);

      if (data.message === "Certified") {
        await fetchOwnership();
        await showAppToAppCertifyButton(certifyapplicationName);
        toast.info(certifyapplicationName + " - " + data.message, {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        setDisableCertify1apptoapp(true);
        props.setProgress(70);

        await getCertificationPercentage(certifyapplicationName);
        await showAppToAppCertifyButton(certifyapplicationName);
        props.setProgress(100);
      } else {
        console.error("Failed to fetch certify");
      }
    } catch (error) {
      if (error.message === "Timeout") {
        toast.error("Request Timed Out", {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      } else {
        console.error("An error occurred:", error);
        toast.error("Certify Error", {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
    }
  };

  const handleRelationshipCertify = async () => {
    props.setProgress(10);
    try {
      const response = await fetchWithTimeout(
        `${process.env.REACT_APP_BACKEND_URI}/certify`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
          body: JSON.stringify({
            applicationName: certifyapplicationName,
            logged_in_user: graphData?.displayName,
            relationship_category: "app_to_server",
          }),
        }
      );
      const data = await response.json();
      props.setProgress(50);

      if (data.message === "Certified") {
        await fetchOwnership();
        await showRelationshipCertifyButton(certifyapplicationName);
        toast.info(certifyapplicationName + " - " + data.message, {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        setDisableCertify1(true);
        props.setProgress(70);

        await getCertificationPercentage(certifyapplicationName);
        await showRelationshipCertifyButton(certifyapplicationName);
        props.setProgress(100);
      } else {
        console.error("Failed to fetch certify");
      }
    } catch (error) {
      if (error.message === "Timeout") {
        toast.error("Request Timed Out", {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      } else {
        console.error("An error occurred:", error);
        toast.error("Certify Error", {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
    }
  };



  useEffect(() => {
    if (preventEffect) {
      setPreventEffect(false);
      return;
    }

    const getApplicationSuggestion = setTimeout(async () => {
      const searchBox = document.getElementById("search-bar2");
      const suggestionBox = document.getElementById("suggestion-container2");
      const searchTerm = searchBox.value;
      setArrowStatus(false);
      if (searchTerm.length === 0) {
        suggestionBox.innerHTML = "";
        suggestionBox.style.display = "none";
        searchBox.style.borderBottomLeftRadius = "0.25rem";
        searchBox.style.borderBottomRightRadius = "0.25rem";
        searchBox.style.borderTopRightRadius = "0.25rem";
        searchBox.style.border = "1px solid #ccc";
        return;
      }

      if (searchTerm.length >= 3) {
        suggestionBox.innerHTML = "Loading...";
        suggestionBox.style.display = "block";
        searchBox.style.borderBottomLeftRadius = "0%";
        searchBox.style.borderBottomRightRadius = "0%";
        suggestionBox.style.padding = "10px";

        try {
          let url;
          if (/^[Aa]\d{2,}$/.test(searchTerm)) {
            url = `${process.env.REACT_APP_BACKEND_URI}/getappidlist?applicationID=${encodeURIComponent(searchTerm)}`;
          } else {
            url = `${process.env.REACT_APP_BACKEND_URI}/getappsforcertify?applicationName=${encodeURIComponent(searchTerm)}`;
          }

          const response = await fetchWithTimeout(url, {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          });

          const data = await response.json();
          suggestionBox.innerHTML = "";

          if (data.length > 0) {
            suggestionBox.style.display = "block";
            searchBox.style.borderBottomLeftRadius = "0%";
            searchBox.style.borderBottomRightRadius = "0%";
            suggestionBox.style.padding = "0px";

            const maxSuggestions = 20;
            for (let i = 0; i < data.length && i < maxSuggestions; i++) {
              const suggestionItem = document.createElement("div");
              suggestionItem.classList.add("suggestion-item");
              suggestionItem.textContent = data[i];

              suggestionItem.addEventListener("click", async function () {
                await setPreventEffect(true);
                await setSearchbarName(data[i]);
                await setcertifyapplicationName(data[i]);
                setSupportGroupreadOnly(true);
                setSupportGroupreadOnly2(true);
                handleDiscardClick();
                setShowSupportGroupButtons2(false);
                setShowSupportGroupButtons(false);
                searchBox.style.borderBottomLeftRadius = "0.25rem";
                searchBox.style.borderBottomRightRadius = "0.25rem";
                suggestionBox.style.display = "none";

                if (/^[Aa]\d{2,}$/.test(searchTerm)) {
                  await fetchAdditionalAppData(data[i], props);
                } else {
                  await fetchGeneralAppData(data[i], props);
                }
              });

              suggestionBox.appendChild(suggestionItem);
            }
          } else {
            suggestionBox.style.display = "none";
            searchBox.style.borderBottomLeftRadius = "0.25rem";
            searchBox.style.borderBottomRightRadius = "0.25rem";
          }
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      }
    }, 500);

    return () => clearTimeout(getApplicationSuggestion);
  }, [searchbarName]);

  useEffect(() => {
    if (readOnly) {
      const timeout = setTimeout(() => {
        handleiconclick();
        setLastUpdatedField(null);
      }, 500);
      return () => clearTimeout(timeout);
    }
  }, [tempData]);
  let timeout = null;
  const handleChange = (e, category) => {
    setTempData({
      ...tempData,
      [e.target.name]: {
        displayValue: e.target.value,
        value: "",
        email: "", // Adding sysid as empty string as per the schema
      },
    });
    setLastUpdatedField(e.target.name);
    // if (timeout) clearTimeout(timeout);

    // timeout = setTimeout(() => {
    //   handleiconclick(e, category);
    // }, 500); // 300ms delay
  };
  const handleSupportGroupChange = (e, category) => {
    console.log(operationalStatusOptions[e.target.value]);
    console.log(e.target.value);
    setSupportGroupUpdatedData({
      ...supportGroupUpdatedData,
      [e.target.name]: {
        displayValue: e.target.value, // Retain existing fields
        previousvalue: ownershipdata[e.target.name].displayValue,
        value:
          e.target.name === "operational_status"
            ? operationalStatusOptions[e.target.value]
            : e.target.name === "u_application_type"
            ? e.target.value
            : supportGroupUpdatedData[e.target.name]?.value,
      },
    });
    setLastUpdatedField(e.target.name);

    // if (timeout) clearTimeout(timeout);

    // timeout = setTimeout(() => {
    //   handleiconclick(e, category);
    // }, 500); // 300ms delay
  };
  const handleCrossClick = (e) => {
    document.getElementsByName(e.target.id)[2].style.display = "none";
    document.getElementsByName(e.target.id)[0].style.borderBottomLeftRadius =
      "5px";
    document.getElementsByName(e.target.id)[1].style.borderBottomRightRadius =
      "5px";
    setDropdownStates({
      ...dropdownStates,
      [e.target.id]: true,
    });
  };

  const getMacDetails = async (applicationName) => {
    try {
      const response = await fetchWithTimeout(
        `${
          process.env.REACT_APP_BACKEND_URI
        }/getmacdetails?applicationName=${encodeURIComponent(applicationName)}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      const data = await response.json();
      setMacDetails(data);
      console.log("MAC Details", data);
    } catch (error) {}
  };

  const getCertificationPercentage = async (applicationName) => {
    try {
      const response = await fetchWithTimeout(
        `${process.env.REACT_APP_BACKEND_URI}/getcertificationpercentage`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
          body: JSON.stringify({
            applicationName: applicationName,
          }),
        }
      );
      const result = await response.json();
      // //console.log("Certification Percentage", result);
      await setcertifypercentage(result.percentage);
      await setappenv(result.environment[0]);
      //console.log(typeof result.environment[0]);
      // props.setProgress(100);
    } catch (error) {
      if (error.message === "Timeout") {
        toast.error("Request Timed Out", {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
      // props.setProgress(100);
    }
  };

  async function showAppToAppCertifyButton(application) {
    try {
      // //console.log(application);
      setrelationcertifiedapptoapp(false);
      const response = await fetchWithTimeout(
        `${process.env.REACT_APP_BACKEND_URI}/showcertify`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
          body: JSON.stringify({
            applicationName: application,
            logged_in_user: graphData?.displayName,
            relationship_category: "app_to_app",
          }),
        }
      );
      const result = await response.json();
      //console.log("data", result);
      const userCheckResult = result.user_check;
      // //console.log(userCheckResult);
      const pendingRequestResult = result.pending_request;
      // //console.log(pendingRequestResult);
      const certificationResult1 = result.certification;
      // //console.log(certificationResult1);
      if (
        userCheckResult == "invalid" &&
        pendingRequestResult == "invalid" &&
        certificationResult1 == "invalid"
      ) {
        setDisableCertify1apptoapp(true);
      } else {
        if (userCheckResult === "Verified") {
          if (pendingRequestResult === "Pending request") {
            setDisableCertify1apptoapp(true);
            setShowTooltip1apptoapp(
              "Please clear the pending requests to certify the application"
            );

            if (certificationResult1 !== "Certify") {
              setShowTooltip2apptoapp(certificationResult1.tooltip1);
              setrelationcertifiedapptoapp(true);
            }
          }
          if (
            pendingRequestResult === "No pending request" &&
            certificationResult1 === "Certify"
          ) {
            //console.log("Certify button enabled");
            setDisableCertify1apptoapp(false);
            setShowTooltip1apptoapp(
              "As Application Manager, you can review the application to server relationships and validate by clicking the Certify button at every 180 days"
            );
          }
          if (
            pendingRequestResult === "No pending request" &&
            certificationResult1 !== "Certify"
          ) {
            if (certificationResult1 == "Not in Production") {
              setrelationcertifiedapptoapp(false);
            }
            if (certificationResult1 != "Not in Production") {
              setrelationcertifiedapptoapp(true);
            }
            setShowTooltip2apptoapp(certificationResult1.tooltip1);
            setDisableCertify1apptoapp(true);
            if (
              certificationResult1.tooltip2.includes("Certification expire")
            ) {
              setDisableCertify1apptoapp(false);
            }
            setShowTooltip1apptoapp(
              certificationResult1 && certificationResult1.tooltip2
                ? certificationResult1.tooltip2
                : "Unknown error occurred"
            );
          }
        }
        if (userCheckResult === "Not Verified") {
          if (certificationResult1 == "Certify") {
            setrelationcertifiedapptoapp(false);
          }
          if (certificationResult1 != "Certify") {
            if (certificationResult1 == "Not in Production") {
              setrelationcertifiedapptoapp(false);
            }
            if (certificationResult1 != "Not in Production") {
              setrelationcertifiedapptoapp(true);
            }
            setShowTooltip2apptoapp(certificationResult1.tooltip1);
          }
          // setrelationcertified(false);
          setDisableCertify1apptoapp(true);
          setShowTooltip1apptoapp(
            "You need to be the Application Manager to certify"
          );
        }
        if (certificationResult1 == "retired") {
          setDisableCertify1apptoapp(true);
          setrelationcertifiedapptoapp(false);
          setShowTooltip1apptoapp("Application is retired");
        }
      }
    } catch (error) {
      if (error.message === "Timeout") {
        toast.error("Request Timed Out", {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
    }
  }

  async function showRelationshipCertifyButton(application) {
    try {
      //console.log(application);
      setrelationcertified(false);
      setMarkedAsSaaS(false);
      const response = await fetchWithTimeout(
        `${process.env.REACT_APP_BACKEND_URI}/showcertify`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
          body: JSON.stringify({
            applicationName: application,
            logged_in_user: graphData?.displayName,
            relationship_category: "app_to_server",
          }),
        }
      );
      const result = await response.json();
      //console.log("data", result);
      const userCheckResult = result.user_check;
      // //console.log(userCheckResult);
      const pendingRequestResult = result.pending_request;
      // //console.log(pendingRequestResult);
      const certificationResult1 = result.certification;
      // //console.log(certificationResult1);
      if (
        userCheckResult == "invalid" &&
        pendingRequestResult == "invalid" &&
        certificationResult1 == "invalid"
      ) {
        setDisableCertify1(true);
        setShowSaaS1(false);
      } else {
        if (userCheckResult === "Verified") {
          setShowSaaS1(true);
          setShowSaaSTooltip1("");
          if (pendingRequestResult === "Pending request") {
            setDisableCertify1(true);
            setShowTooltip1(
              "Please clear the pending requests to certify the application"
            );

            if (certificationResult1 === "Certify") {
              setrelationcertified(false);
            }
            if (certificationResult1 !== "Certify") {
              setShowTooltip2(certificationResult1.tooltip1);
              setrelationcertified(true);
            }
          }
          if (
            pendingRequestResult === "No pending request" &&
            certificationResult1 === "Certify"
          ) {
            //console.log("Certify button enabled");
            setDisableCertify1(false);
            setShowTooltip1(
              "As Application Manager, you can review the application to server relationships and validate by clicking the Certify button at every 180 days"
            );
          }
          if (
            pendingRequestResult === "No pending request" &&
            certificationResult1 !== "Certify"
          ) {
            if (certificationResult1 == "Not in Production") {
              setrelationcertified(false);
            }
            if (certificationResult1 != "Not in Production") {
              setrelationcertified(true);
            }
            setShowTooltip2(certificationResult1.tooltip1);
            setDisableCertify1(true);
            if (
              certificationResult1.tooltip2.includes("Certification expire")
            ) {
              setDisableCertify1(false);
            }
            setShowTooltip1(
              certificationResult1 && certificationResult1.tooltip2
                ? certificationResult1.tooltip2
                : "Unknown error occurred"
            );
          }
          const saasResponse = await fetchWithTimeout(
            `${process.env.REACT_APP_BACKEND_URI}/get_saas_apps?applicationName=${encodeURIComponent(application)}`,
            {
              method: "GET",
              headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
              },
            }
          );
          if (saasResponse.ok) {
            saasResponse.json().then(responseText => {
              if (responseText === "Application is Saas") {
                  setDisableSaaS1(true);
                  setShowSaaSTooltip1("Application is already marked as SaaS");
                  setrelationcertified(true);
                  setMarkedAsSaaS(true);
                  if (certificationResult1 === "Certify") {
                    setShowTooltip2("Marked as SaaS");
                  } else {
                    setShowTooltip2(prevResult => prevResult + " & Marked as SaaS");
                  }
              } else if (responseText === "Application is not Saas") {
                  setDisableSaaS1(false);
                  setShowSaaSTooltip1("Certify that your app is a SaaS application");
              } else {
                toast.error("Error while fetching SaaS status of application", {
                  position: "top-center",
                  autoClose: 3000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                  theme: "light",
                });
              }
            });
          } else {
            toast.error("Error while fetching SaaS status of application", {
              position: "top-center",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            });
          }
        }
        if (userCheckResult === "Not Verified") {
          setShowSaaS1(false);
          setShowSaaSTooltip1("You need to be the Application Manager to mark/unmark as SaaS");
          if (certificationResult1 == "Certify") {
            setrelationcertified(false);
          }
          if (certificationResult1 != "Certify") {
            if (certificationResult1 == "Not in Production") {
              setrelationcertified(false);
            }
            if (certificationResult1 != "Not in Production") {
              setrelationcertified(true);
            }
            setShowTooltip2(certificationResult1.tooltip1);
          }
          // setrelationcertified(false);
          setDisableCertify1(true);
          setShowTooltip1("You need to be the Application Manager to certify");
          const saasResponse = await fetchWithTimeout(
            `${process.env.REACT_APP_BACKEND_URI}/get_saas_apps?applicationName=${encodeURIComponent(application)}`,
            {
              method: "GET",
              headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
              },
            }
          );
          if (saasResponse.ok) {
            saasResponse.json().then(responseText => {
              if (responseText === "Application is Saas") {
                  setDisableSaaS1(true);
                  setrelationcertified(true);
                  setMarkedAsSaaS(true);
                  if (certificationResult1 === "Certify") {
                    setShowTooltip2("Marked as SaaS");
                  } else {
                    setShowTooltip2(prevResult => prevResult + " & Marked as SaaS");
                  }
              } else if (responseText === "Application is not Saas") {
                  setDisableSaaS1(false);
              } else {
                toast.error("Error while fetching SaaS status of application", {
                  position: "top-center",
                  autoClose: 3000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                  theme: "light",
                });
              }
            });
          } else {
            toast.error("Error while fetching SaaS status of application", {
              position: "top-center",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            });
          }
        }
        if (certificationResult1 == "retired") {
          setDisableCertify1(true);
          setrelationcertified(false);
          setShowSaaS1(false);
          setShowTooltip1("Application is retired");
          toast.warning("Application is retired", {
            position: "top-center",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });




        }
      }
    } catch (error) {
      if (error.message === "Timeout") {
        toast.error("Request Timed Out", {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
    }
  }
  const handleSaveClick = async () => {
    if (
      tempData.owned_by.displayValue == "" ||
      tempData.managed_by.displayValue == ""
    ) {
      toast.error("Please fill  the required fields", {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      return;
    }

    setshowbuttons(false);

    props.setProgress(10);
    try {
      const {
        owned_by,
        managed_by,
        u_engineering_manager,
        u_sr_engineering_manager,
        u_engineering_director,
        sys_id,
      } = ownershipdata;
      const originaldata = {
        owned_by,
        managed_by,
        u_engineering_manager,
        u_sr_engineering_manager,
        u_engineering_director,
        sys_id,
      };

      const {
        owned_by: temp_owned_by,
        managed_by: temp_managed_by,
        u_engineering_manager: temp_u_engineering_manager,
        u_sr_engineering_manager: temp_u_sr_engineering_manager,
        u_engineering_director: temp_u_engineering_director,
        sys_id: temp_sys_id,
      } = tempData;
      const updateddata = {
        owned_by: temp_owned_by,
        managed_by: temp_managed_by,
        u_engineering_manager: temp_u_engineering_manager,
        u_sr_engineering_manager: temp_u_sr_engineering_manager,
        u_engineering_director: temp_u_engineering_director,
        sys_id: temp_sys_id,
      };
      const response = await fetchWithTimeout(
        `${process.env.REACT_APP_BACKEND_URI}/sendownershipdata`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
          body: JSON.stringify({
            applicationName: certifyapplicationName,
            originaldata: originaldata,
            updateddata: updateddata,
            requestor: graphData?.displayName,
          }),
        }
      );
      props.setProgress(50);
      //console.log("response", response);

      await getOwnershipInfo(certifyapplicationName);
      toast.success("Ownership data saved successfully", {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      props.setProgress(100);
    } catch (error) {
      if (error.message === "Timeout") {
        toast.error("Request Timed Out", {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      } else {
        console.error("Error saving data:", error);
        toast.error("Error Occured", {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
      props.setProgress(100);
    }
  };

  const handleSupportGroupSaveClick = async () => {
    const {
      vendor,
      operational_status,
      sys_id,
      u_application_type,
      change_control,
    } = supportGroupUpdatedData;

    const filteredSupportGroupData = {
      vendor,
      operational_status,
      sys_id,
      u_application_type,
      change_control,
    };

    try {
      const response = await fetchWithTimeout(
        `${process.env.REACT_APP_BACKEND_URI}/sendsupportgroupdata`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
          body: JSON.stringify({
            applicationName: certifyapplicationName,
            supportGroupUpdatedData: filteredSupportGroupData,
            requestor: graphData?.displayName,
            requestor_email: graphData?.mail,
          }),
        }
      );
      await getOwnershipInfo(certifyapplicationName);
    } catch (error) {}
  };
  const handleSupportGroupSaveClick1 = async () => {
    try {
      const {
        support_group,
        u_service_request_support_group,
        u_support_group_l2,
        u_support_group_l3,
        u_support_group_l4,
        sys_id,
      } = supportGroupUpdatedData;
      const filteredSupportGroupData = {
        support_group,
        u_service_request_support_group,
        u_support_group_l2,
        u_support_group_l3,
        u_support_group_l4,
        sys_id,
      };

      const response = await fetchWithTimeout(
        `${process.env.REACT_APP_BACKEND_URI}/sendsupportgroupdata`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
          body: JSON.stringify({
            applicationName: certifyapplicationName,
            supportGroupUpdatedData: filteredSupportGroupData,
            requestor: graphData?.displayName,
            requestor_email: graphData?.mail,
          }),
        }
      );
      await getOwnershipInfo(certifyapplicationName);
    } catch (error) {}
  };

  const handleDiscardClick = () => {
    setDropdownStates((prevState) => {
      const newState = {};
      for (let key in prevState) {
        newState[key] = true;
      }
      return newState;
    });

    const elements1 = document.querySelectorAll(".suggestion-container3");
    //console.log(elements1);
    elements1.forEach((element) => {
      element.style.display = "none";
    });
    const elements2 = document.querySelectorAll(".search-bar");
    //console.log(elements2);
    elements2.forEach((element) => {
      element.style.borderBottomLeftRadius = "5px";
    });

    const elements3 = document.querySelectorAll(".search-icon");
    //console.log(elements3);
    elements3.forEach((element) => {
      element.style.borderBottomRightRadius = "5px";
    });
    setTempData({ ...ownershipdata });
    setEditable(false);
  };
  const handleOwnershipCertification = async () => {
    props.setProgress(10);
    try {
      const response = await fetchWithTimeout(
        `${process.env.REACT_APP_BACKEND_URI}/certifyownership`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
          body: JSON.stringify({
            applicationName: certifyapplicationName,
            logged_in_user: graphData?.displayName,
          }),
        }
      );
      const data = await response.json();
      props.setProgress(50);
      if (data.message === "Certified") {
        await fetchOwnership();
        toast.info(certifyapplicationName + " - " + data.message, {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        // setDisableCertify1(true);
        await getCertificationPercentage(certifyapplicationName);
        await getCertifyTooltip(certifyapplicationName);
        props.setProgress(100);
      } else {
        console.error("Failed to fetch certify");
      }
    } catch (error) {
      if (error.message === "Timeout") {
        toast.error("Request Timed Out", {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      } else {
        console.error("An error occurred:", error);
        toast.error("Certify Error", {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
    }
  };
  const handleiconclick = async (e, category) => {
    if (!lastUpdatedField) return;
    if (readOnly) {
      const category = lastUpdatedField;
      const searchbox = document.getElementById("search-icon");
      //console.log(searchbox);
      // setDropdownStates({
      //   ...dropdownStates,
      //   [e.target.id]: false,
      // });

      //console.log("clicked", e.target.id);
      const elements = document.getElementsByName(category);
      //console.log(elements);
      const searchBox = document.getElementsByName(category)[0];
      const suggestionBox = document.getElementsByName(category)[2];
      const searchicon = document.getElementsByName(category)[1];
      const elements1 = document.querySelectorAll(".suggestion-container3");
      //console.log(elements1);
      elements1.forEach((element) => {
        element.style.display = "none";
      });
      const elements2 = document.querySelectorAll(".search-bar");
      //console.log(elements2);
      elements2.forEach((element) => {
        element.style.borderBottomLeftRadius = "5px";
      });

      const elements3 = document.querySelectorAll(".search-icon");
      //console.log(elements3);
      elements3.forEach((element) => {
        element.style.borderBottomRightRadius = "5px";
      });

      const field = lastUpdatedField;
      const searchTerm = tempData[category]?.displayValue;

      console.log(searchTerm.length);
      if (searchTerm.length == 0) {
        suggestionBox.style.display = "none";
        suggestionBox.innerHTML = "";
        searchBox.style.borderBottomLeftRadius = "5px";
        searchicon.style.borderBottomRightRadius = "5px";
      }
      if (searchTerm.length >= 3) {
        console.log(searchTerm);

        suggestionBox.style.display = "block";

        setDropdownStates((prevState) => {
          const newState = {};
          for (let key in prevState) {
            if (key === category) {
              newState[key] = false;
            } else {
              newState[key] = true;
            }
          }
          return newState;
        });
        searchBox.style.borderBottomLeftRadius = "0%";
        searchicon.style.borderBottomRightRadius = "0%";
        suggestionBox.innerHTML = "<div>Loading...</div>";
        await fetch(
          `${
            process.env.REACT_APP_BACKEND_URI
          }/fetch_user_data?UserName=${encodeURIComponent(searchTerm)}`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        )
          .then((response) => response.json())
          .then((data) => {
            // Clear previous suggestions
            suggestionBox.innerHTML = "";
            if (searchTerm.trim() === "") {
              suggestionBox.innerHTML = "";
              suggestionBox.style.display = "none";
              return;
            }
            // Display a maximum of 20 suggestions
            // const maxSuggestions = 20;
            for (let i = 0; i < data.length; i++) {
              const suggestionItem = document.createElement("div");
              suggestionItem.classList.add("suggestion-item");
              // suggestionItem.textContent = data[i];
              // suggestionItem.innerHTML = "";
              //console.log(data[i]);
              //console.log(data[i].name);

              suggestionItem.innerHTML = ` ${
                data[i].name == undefined ? "No results" : data[i].name
              } <br> ${data[i].email == undefined ? "" : data[i].email}`;
              if (data[i].name != undefined) {
                suggestionItem.addEventListener("click", async function () {
                  setTempData({
                    ...tempData,
                    [field]: {
                      displayValue: data[i].name,
                      value: data[i].sys_id,
                      email: data[i].email, // Adding sysid as empty string as per the schema
                    },
                  });
                  searchBox.style.borderBottomLeftRadius = "5px";
                  searchicon.style.borderBottomRightRadius = "5px";
                  setDropdownStates((prevState) => {
                    const newState = {};
                    for (let key in prevState) {
                      newState[key] = true;
                    }
                    return newState;
                  });
                  suggestionBox.style.display = "none"; // Hide the suggestion box on click
                });
              }
              suggestionBox.appendChild(suggestionItem);
            }
            if (data.length > 0) {
              suggestionBox.style.display = "block";
              searchBox.style.borderBottomLeftRadius = "0%";
              searchicon.style.borderBottomRightRadius = "0%"; // Show the suggestion box
            } else {
              setDropdownStates((prevState) => {
                const newState = {};
                for (let key in prevState) {
                  if (key === category) {
                    newState[key] = true;
                  } else {
                    newState[key] = false;
                  }
                }
                return newState;
              });
              suggestionBox.innerHTML = "";
              suggestionBox.style.display = "none";
              searchBox.style.borderBottomLeftRadius = "5px";
              searchicon.style.borderBottomRightRadius = "5px"; // Hide if there are no suggestions
            }
          })
          .catch((error) => {
            console.error("Error fetching data:", error);
          });
      }
      if (searchTerm.length == 0) {
        setDropdownStates({
          ...dropdownStates,
          [category]: true,
        });
        console.log("No data");
        while (suggestionBox.firstChild) {
          suggestionBox.removeChild(suggestionBox.firstChild);
        }
        suggestionBox.style.display = "none";
        searchBox.style.borderBottomLeftRadius = "5px";
        searchicon.style.borderBottomRightRadius = "5px"; // Hide if the input length is less than 3
      }
    }
  };
  const getCertifyTooltip = async (applicationName) => {
    try {
      const response = await fetchWithTimeout(
        `${process.env.REACT_APP_BACKEND_URI}/getownercertifytooltip`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
          body: JSON.stringify({
            applicationName: applicationName,
          }),
        }
      );
      const result = await response.json();
      if (result == "Not Certified") {
        setownershipcertified(false);
        setownershipTooltip(
          "As an owner, you can certify ownership of the application"
        );
      } else if (result == "retired") {
        setownershipcertified(false);
        setownershipTooltip("App is retired");
        // setisciadmingrp(true);
      } else {
        setownershipcertified(true);
        setownershipTooltip(result);
      }
    } catch (error) {
      if (error.message === "Timeout") {
        toast.error("Request Timed Out", {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
    }
  };
  const handlemarkassaas = async () => {
    try {
      const response = await fetchWithTimeout(`${process.env.REACT_APP_BACKEND_URI}/markassaas`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
        body: JSON.stringify({
          applicationName: certifyapplicationName,
          username: graphData?.displayName,
        }),
      });
      const data = await response.json();
      if (data === "Success") {
        toast.info(certifyapplicationName + " - Successfully marked as SaaS", {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        setDisableSaaS1(true);
        setShowSaaSTooltip1("Application is already marked as SaaS");
        setrelationcertified(false);
        setMarkedAsSaaS(true);
        await getCertificationPercentage(certifyapplicationName);
      } else {
        console.error("Failed to mark app as SaaS");
      }
    } catch (error) {
      if (error.message === "Timeout") {
        toast.error("Request Timed Out", {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      } else {
        console.error("An error occurred:", error);
        toast.error("Error while marking as SaaS", {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
    }
  };

  const handleunmarkassaas = async () => {
    try {
      const response = await fetchWithTimeout(`${process.env.REACT_APP_BACKEND_URI}/unmarkassaas`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
        body: JSON.stringify({
          applicationName: certifyapplicationName,
          username: graphData?.displayName,
        }),
      });
      const data = await response.json();
      if (data === "Success")
      {
        toast.info(certifyapplicationName + " - Successfully unmarked as SaaS", {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        setDisableSaaS1(false);
        setShowSaaSTooltip1("Certify that your app is a SaaS application");
        setrelationcertified(false);
        setMarkedAsSaaS(false);
        await getCertificationPercentage(certifyapplicationName);
      } else {
        console.error("Failed to mark app as SaaS");
      }
    } catch (error) {
      if (error.message === "Timeout") {
        toast.error("Request Timed Out", {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      } else {
        console.error("An error occurred:", error);
        toast.error("Error while marking as SaaS", {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
    }
  };

  const handleSaaSClick = async () => {
    if (!disableSaaS1) {
      await toast.promise(handlemarkassaas(), {
        pending: "Marking Application as SaaS",
      });
    } else {
      await toast.promise(handleunmarkassaas(), {
        pending: "Unmarking Application as SaaS",
      });
    }
  };

  return (
    <div style={{paddingTop:"60px"}}>
      <div className="container3">
        <div className="row" style={{ height: "40px" }}>
          <div className="col-md-8">Select an application</div>
          <div
            className="col-md-4"
            style={{
              overflow: "hidden",
            }}
          >
            <div style={{ width: "300px" }}>
              <div style={{ display: "flex" }}>
                <input
                  id="search-bar2"
                  name="search-bar2"
                  style={{
                    width: "100%",
                    border: "1px solid #ccc",
                    borderRight: arrowStatus ? "none" : "1px solid #ccc",
                    borderRadius: "0.25rem",
                    padding: "0.3rem",
                  }}
                  type="text"
                  onChange={handleapplicationNameChange}
                  value={searchbarName}
                  autoComplete="off"
                ></input>
                <button
                  id="suggestion-button"
                  style={{
                    display: arrowStatus ? "block" : "none",
                    border: "1px solid #ccc",
                    borderLeft: "none",
                    backgroundColor: "white",
                    borderTopRightRadius: "0.25rem",
                    borderBottomRightRadius: "0.25rem",
                    padding: "0rem 0.5rem",
                  }}
                  onClick={(event) => {
                    const searchbarName =
                      document.getElementById("search-bar2").value;
                    const selectedAppId = searchbarName.substring(0, 5);
                    const suggestionBox = document.getElementById(
                      "suggestion-container2"
                    );

                    // Toggle suggestion box visibility
                    if (suggestionBox.style.display === "block") {
                      suggestionBox.style.display = "none";
                    } else {
                      suggestionBox.style.display = "block";
                      suggestionBox.innerHTML = "";
                      HandleSuggestionAppIdNames(selectedAppId, suggestionBox);
                    }
                  }}
                >
                  <span
                    style={{
                      display: "inline-block",
                      transform: "rotate(90deg) scale(1.3)", // Initial transform
                    }}
                  >
                    &gt;
                  </span>
                </button>
              </div>

              <div
                id="suggestion-container2"
                style={{
                  width: "100%",
                  maxHeight: "100px",
                  backgroundColor: "white",
                  zIndex: 10,
                  position: "relative",
                  display: "none",
                  borderTopLeftRadius: "0px",
                  overflowY: "auto",
                }}
              ></div>
            </div>
          </div>
        </div>
        <div
          className="row"
          // style={{
          //   display: "flex",
          //   justifyContent: "space-between",
          //   margin: "12px",
          //   borderbottom: "inset",
          // }}
        >
          <span
            style={{
              width: "fit-content",
              position: "absolute",
              top: "195px",
              boxShadow: "none",
              fontSize: "0.7rem",
              fontWeight: "bold",
              background: "#0073AB",
              borderRadius: "999px",

              color: "white",
              border: "none",
              marginleft: "12px",
            }}
          >
            ServiceNow
          </span>
          <span style={{ width: "fit-content" }}>
            Application ID : {ownershipdata?.u_application_id?.displayValue}
          </span>
          <span style={{ width: "fit-content" }}>
            Platform Name : {ownershipdata?.u_business_platform?.displayValue}
          </span>
          <span style={{ width: "fit-content" }}>
            Portfolio Name : {ownershipdata?.u_platform_portfolio?.displayValue}
          </span>
        </div>
        <div
          className="row"
          // style={{
          //   display: "flex",
          //   justifyContent: "space-between",
          //   margin: "12px",
          //   borderbottom: "inset",
          // }}
        >
          <span
            style={{
              width: "fit-content",
              position: "absolute",
              top: "245px",
              fontWeight: "bold",
              boxShadow: "none",
              fontSize: "0.7rem",
              marginleft: "12px",

              background: "#0073AB",
              borderRadius: "999px",

              color: "white",
              border: "none",
            }}
          >
            MAC
          </span>
          <span style={{ width: "fit-content" }}>
            Application ID : {macDetails?.applicationId}
          </span>
          <span style={{ width: "fit-content" }}>
            Platform Name : {macDetails?.platformName}
          </span>
          <span style={{ width: "fit-content" }}>
            Portfolio Name : {macDetails?.portfolioName}
          </span>
        </div>
        <div className="row">
          <div className="col-md-8">Certification Percentage </div>
          <div className="col-md-4 text-right">
            <span
              style={{
                color:
                  certifypercentage === 100
                    ? "green"
                    : certifypercentage === 50
                    ? "orange"
                    : certifypercentage === 75
                    ? "#FFBF00"
                    : certifypercentage === ""
                    ? "black"
                    : "red",
              }}
              id="percentage"
            >
              {certifypercentage?.length == 0 ? "0%" : certifypercentage + "%"}
              {/* {certifypercentage + "%"} */}
            </span>
          </div>
        </div>
        <div className="row">
          <div className="col-md-8">
            Mark Application as SaaS
            <span
              style={{
                marginLeft: "3px",
              }}
            >
              {markedAsSaaS ? (
                <div
                  style={{
                    fontSize: "12px",
                  }}
                >
                  {<FaCheckCircle
                      size={20}
                      style={{
                        color: "#198754",
                        marginLeft: "5px",
                      }}
                    />
                  }
                  &nbsp;
                  {"Marked as SaaS"}
                </div>
              ) : null}
            </span>
          </div>
          <div className="col-md-4">
            <div style={{ display: "inline-block" }}>
              <div
                style={{
                  marginLeft: "15px",
                  display: "flex",
                  justifyContent: "flex-start",
                }}
              >
                <div style={{ display: "inline-block" }}>
                  <div
                    style={{
                      marginLeft: "15px",
                      display: "flex",
                      justifyContent: "flex-start",
                    }}
                  >
                    <div
                      data-tooltip-id="saas-tooltip"
                      data-tooltip-hidden={showSaaS1}
                      data-tooltip-place="bottom-start"
                      style={{
                        cursor: !{ showSaaS1 } ? "not-allowed" : "pointer",
                        whiteSpace: "nowrap"
                      }}
                    >
                      <Button
                        appearance="alt"
                        fit="medium"
                        id="primary"
                        justifyItems="center"
                        label={!disableSaaS1 ? 'Mark as SaaS' : 'Unmark as SaaS'}
                        name="primary"
                        disabled={!showSaaS1}
                        onClick={function noRefCheck() {
                          handleSaaSClick();
                        }}
                        title="Button"
                        variant="filledAlt"
                        style={{ marginLeft: "10px",
                          whiteSpace: "nowrap",
                        }}
                      />
                    </div>
                    <div
                      data-tooltip-id="info"
                      data-tooltip-content={showSaaSTooltip1}
                      data-tooltip-place="top-start"
                      style={{
                        margin: "7px 0px 0px 10px",
                        display: "inline-block",
                      }}
                    >
                      <Icon name="info-circle" size="24" />
                    </div>
                    <Tooltip id="info" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-8">
            App to Server Relationships
            <span
              style={{
                marginLeft: "3px",
              }}
            >
              {relationcertified ? (
                <div
                  style={{
                    fontSize: "12px",
                  }}
                >
                  {/* <FcApproval size={20} />
                   */}
                  {showTooltip2?.includes("Last Certified") ? (
                    <FaExclamationCircle
                      size={20}
                      style={{
                        color: "#ffc107",
                        marginRight: "2px",
                        marginLeft: "5px",
                      }}
                    />
                  ) : (
                    <FaCheckCircle
                      size={20}
                      style={{
                        color: "#198754",
                        marginLeft: "5px",
                      }}
                    />
                  )}
                  &nbsp;
                  {showTooltip2}
                </div>
              ) : null}
            </span>
          </div>
          <div className="col-md-4">
            <div style={{ display: "inline-block" }}>
              <div
                style={{
                  marginLeft: "15px",
                  display: "flex",
                  justifyContent: "flex-start",
                }}
              >
                <div
                  data-tooltip-id="certify-tooltip"
                  data-tooltip-content={
                    certificationSuccessful1 ? "" : "Approvals Pending"
                  }
                  data-tooltip-hidden={!disableCertify1}
                  data-tooltip-place="bottom-start"
                  style={{
                    cursor: { disableCertify1 } ? "not-allowed" : "pointer",
                  }}
                >
                  <Button
                    appearance="alt"
                    fit="medium"
                    id="primary"
                    justifyItems="center"
                    label="View"
                    name="primary"
                    onClick={function noRefCheck() {
                      if (certifyapplicationName == "") {
                        toast.error("Please select an application", {
                          position: "top-center",
                          autoClose: 3000,
                          hideProgressBar: false,
                          closeOnClick: true,
                          pauseOnHover: true,
                          draggable: true,
                          progress: undefined,
                          theme: "light",
                        });
                      } else {
                        setapplicationName(certifyapplicationName);
                        setRelationshipCategorySelected(
                          relationshipCategory[1]
                        );
                        navigate("/main");
                        setautosearch(true);
                        setSuggestionSelected(true);
                        setSelectedSuggestion(true);
                        // }
                      }
                    }}
                    title="Button"
                    variant="filled"
                    style={{ width: "80px" }}
                  />
                  <Button
                    appearance="alt"
                    fit="medium"
                    id="primary"
                    justifyItems="center"
                    label="Certify"
                    name="primary"
                    disabled={disableCertify1}
                    onClick={function noRefCheck() {
                      handleRelationshipCertify();
                    }}
                    title="Button"
                    variant="filledAlt"
                    style={{ marginLeft: "10px" }}
                  />
                  {/* <Tooltip id="certify-tooltip" /> */}
                </div>
                <div
                  data-tooltip-id="info"
                  data-tooltip-content={showTooltip1}
                  // data-tooltip-hidden={appenv == "Production" ? false : true}
                  data-tooltip-place="top-start"
                  style={{
                    margin: "7px 0px 0px 10px",
                    display: "inline-block",
                  }}
                >
                  <Icon name="info-circle" size="24" />
                </div>
                <Tooltip id="info" />
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-8">
            App to App Relationships
            <span
              style={{
                marginLeft: "3px",
              }}
            >
              {relationcertifiedapptoapp ? (
                <div
                  style={{
                    fontSize: "12px",
                  }}
                >
                  {/* <FcApproval size={20} /> */}
                  {showTooltip2apptoapp?.includes("Last Certified") ? (
                    <FaExclamationCircle
                      size={20}
                      style={{
                        color: "#ffc107",
                        marginRight: "2px",
                        marginLeft: "5px",
                      }}
                    />
                  ) : (
                    <FaCheckCircle
                      size={20}
                      style={{
                        color: "#198754",
                        marginLeft: "5px",
                      }}
                    />
                  )}
                  &nbsp;
                  {showTooltip2apptoapp}
                </div>
              ) : null}
            </span>
          </div>
          <div className="col-md-4">
            <div style={{ display: "inline-block" }}>
              <div
                style={{
                  marginLeft: "15px",
                  display: "flex",
                  justifyContent: "flex-start",
                }}
              >
                <div
                  data-tooltip-id="certify-tooltip"
                  data-tooltip-content={
                    certificationSuccessful1 ? "" : "Approvals Pending"
                  }
                  data-tooltip-hidden={!disableCertify1apptoapp}
                  data-tooltip-place="bottom-start"
                  style={{
                    cursor: { disableCertify1apptoapp }
                      ? "not-allowed"
                      : "pointer",
                  }}
                >
                  <Button
                    appearance="alt"
                    fit="medium"
                    id="primary"
                    justifyItems="center"
                    label="View"
                    name="primary"
                    onClick={function noRefCheck() {
                      if (certifyapplicationName == "") {
                        toast.error("Please select an application", {
                          position: "top-center",
                          autoClose: 3000,
                          hideProgressBar: false,
                          closeOnClick: true,
                          pauseOnHover: true,
                          draggable: true,
                          progress: undefined,
                          theme: "light",
                        });
                      } else {
                        if (appenv != "Production") {
                          toast.error(
                            <>
                              This feature is only available for Production
                              Application for now.
                              <br />
                              Current environment: {appenv}
                            </>,
                            {
                              position: "top-center",
                              autoClose: 3000,
                              hideProgressBar: false,
                              closeOnClick: true,
                              pauseOnHover: true,
                              draggable: true,
                              progress: undefined,
                              theme: "light",
                            }
                          );
                        } else {
                          setapplicationName(certifyapplicationName);
                          setRelationshipCategorySelected(
                            relationshipCategory[2]
                          );
                          navigate("/main");
                          setautosearch(true);
                          setSuggestionSelected(true);
                          setSelectedSuggestion(true);
                        }
                      }
                    }}
                    title="Button"
                    variant="filled"
                    style={{ width: "80px" }}
                  />
                  <Button
                    appearance="alt"
                    fit="medium"
                    id="primary"
                    justifyItems="center"
                    label="Certify"
                    name="primary"
                    disabled={disableCertify1apptoapp}
                    onClick={function noRefCheck() {
                      if (appenv == "Production") {
                        handleAppToAppRelationshipCertify();
                        // handleRelationshipCertify();
                      } else {
                        toast.error(
                          <>
                            This feature is only available for Production
                            Application for now.
                            <br />
                            Current environment: {appenv}
                          </>,
                          {
                            position: "top-center",
                            autoClose: 3000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            theme: "light",
                          }
                        );
                      }
                    }}
                    title="Button"
                    variant="filledAlt"
                    style={{ marginLeft: "10px" }}
                  />
                  {/* <Tooltip id="certify-tooltip" /> */}
                </div>
                <div
                  data-tooltip-id="infoapptoapp"
                  data-tooltip-content={showTooltip1apptoapp}
                  data-tooltip-hidden={appenv == "Production" ? false : true}
                  data-tooltip-place="top-start"
                  style={{
                    margin: "7px 0px 0px 10px",
                    display: "inline-block",
                  }}
                >
                  <Icon name="info-circle" size="24" />
                </div>
                <Tooltip id="infoapptoapp" />
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="accordion">
            <div
              style={{
                display: "inline-block",
                verticalAlign: "-0.7em",
              }}
            >
              App Ownership
              <span
                style={{
                  marginLeft: "3px",
                }}
              >
                {ownershipcertified ? (
                  <div
                    style={{
                      display: "inline",
                      fontSize: "12px",
                    }}
                  >
                    {/* <FcApproval size={20} /> */}
                    <FaCheckCircle
                      size={20}
                      style={{
                        color: "#198754",
                        marginLeft: "5px",
                      }}
                    />
                    &nbsp;
                    {ownershipTooltip}
                  </div>
                ) : null}
              </span>
            </div>

            <div
              style={{
                float: "right",
                display: "flex",
              }}
            >
              <Button
                ref={inputRef}
                appearance="alt"
                fit="medium"
                id="primary"
                justifyItems="center"
                label={isViewOpen ? "Hide" : "View"}
                name="primary"
                onClick={(e) => {
                  if (certifyapplicationName != "" || isViewOpen) {
                    handleAccordionclick(e, "accordion", "panel");
                    setIsViewOpen(!isViewOpen);
                  } else {
                    toast.error("Please select an application", {
                      position: "top-center",
                      autoClose: 3000,
                      hideProgressBar: false,
                      closeOnClick: true,
                      pauseOnHover: true,
                      draggable: true,
                      progress: undefined,
                      theme: "light",
                    });
                  }
                }}
                title="Button"
                variant="filled"
                style={{ width: "80px" }}
              />
              <Button
                appearance="alt"
                fit="medium"
                id="primary"
                justifyItems="center"
                label="Certify"
                name="primary"
                onClick={function noRefCheck() {
                  handleOwnershipCertification();
                }}
                title="Button"
                variant="filledAlt"
                disabled={!isciadmingrp}
                style={{ marginLeft: "10px" }}
              />
              <div
                data-tooltip-id="info1"
                data-tooltip-hidden={certifyapplicationName ? false : true}
                data-tooltip-content={
                  isciadmingrp
                    ? ownershipTooltip.includes("retired")
                      ? "App is retired"
                      : ownershipTooltip
                    : ownershipTooltip.includes("retired")
                    ? "App is retired"
                    : "You need to be an owner to certify"
                }
                data-tooltip-place="top-end"
                style={{
                  margin: "7px 0px 0px 10px",
                  display: "inline-block",
                }}
              >
                <Icon name="info-circle" size="24" />
              </div>
              <Tooltip id="info1" />
            </div>
          </div>
          <div className="panel">
            <div
              className="row"
              style={{
                overflow: "visible",
                height: "40px",
                borderBottom: "none",
              }}
            >
              <div className="col-md-8">
                Service Owner :
                <span
                  style={{
                    color: "red",
                    marginLeft: "3px",
                  }}
                >
                  &#42;
                </span>
                <div
                  data-tooltip-id="service_owner"
                  data-tooltip-content="The Service Owner is the main contact for the applications owned, ensuring the CMDB is accurate for key stakeholders. The SO reviews and approves changes, manages major incidents, and coordinates with functional teams like Vendor, Risk, and Audits."
                  data-tooltip-place="top-start"
                  style={{
                    margin: "7px 0px 0px 10px",
                    display: "inline-block",
                  }}
                >
                  <Icon name="info-circle" size="24" />
                </div>
                <Tooltip id="service_owner" style={{ width: "40vw" }} />
              </div>
              <div className="col-md-4">
                <div style={{ flexGrow: "1", height: "40px" }}>
                  <div
                    style={{
                      display: "flex",
                      flexGrow: "1",
                    }}
                  >
                    <input
                      type="text"
                      name="owned_by"
                      id="search-bar"
                      className="search-bar"
                      autoComplete="off"
                      required
                      value={
                        readOnly
                          ? tempData.owned_by.displayValue
                          : ownershipdata?.owned_by.displayValue
                      }
                      disabled={!readOnly}
                      onChange={(e) => {
                        handleChange(e, "owned_by");
                      }}
                      style={{
                        width: "86%",
                        flexGrow: "1",
                        borderTopRightRadius: "0px",
                        borderBottomRightRadius: "0px",
                        borderRight: "none",
                      }}
                    ></input>
                    <div
                      id="search-icon"
                      className="search-icon"
                      name="owned_by"
                      style={{
                        backgroundColor: !readOnly
                          ? "rgba(211, 211, 211, 0.6)"
                          : "#ffffff",
                        padding: "5px",
                        cursor: readOnly ? "default" : "not-allowed",
                        border: "1px solid #ccc",
                        textAlign: "center",
                        borderRadius: "0px 5px 5px 0px",
                        flexGrow: "1",
                        borderLeft: "none",
                      }}
                    >
                      {dropdownStates.owned_by ? (
                        ownershipdata?.owned_by?.user_status == "Inactive" ? (
                          <>
                            <FaUserAltSlash
                              data-tooltip-id="inactive_user"
                              data-tooltip-content="The user is inactive."
                              data-tooltip-place="top-start"
                              size={20}
                              title="Inactive User"
                              // data-tip="Inactive User"
                              style={{
                                color: "#FF6347", // Tomato red
                                verticalAlign: "sub",
                              }}
                            />
                            <Tooltip id="inactive_user" />
                          </>
                        ) : null
                      ) : (
                        <Icon
                          name="times-circle"
                          size="20"
                          id="owned_by"
                          onClick={handleCrossClick}
                          style={{
                            verticalAlign: "-4px",
                            cursor: readOnly ? "not-allowed" : "pointer", // Corrected cursor style
                          }}
                        />
                      )}
                    </div>
                  </div>
                  <div
                    name="owned_by"
                    className="suggestion-container3"
                    style={{
                      flexGrow: "1",
                      maxHeight: "100px",
                      backgroundColor: "white",
                      zIndex: 10,
                      position: "relative",
                      display: "none",
                      borderTopLeftRadius: "0px",
                      overflowY: "auto",
                      backgroundColor: "white",
                      marginLeft: "5px",
                    }}
                  >
                    <div className="suggestion-item">
                      {" "}
                      {isLoading ? <LoadingIndicator></LoadingIndicator> : null}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="row"
              style={{
                overflow: "visible",
                height: "40px",
                borderBottom: "none",
              }}
            >
              <div className="col-md-8">
                Senior Service Owner :
                <span
                  style={{
                    color: "red",
                    marginLeft: "3px",
                  }}
                >
                  &#42;
                </span>
                <div
                  data-tooltip-id="senior_service_owner"
                  data-tooltip-content="The Senior Service Owner has similar responsibilities to the SO but acts as the next escalation point if the SO is unavailable."
                  data-tooltip-place="top-start"
                  style={{
                    margin: "7px 0px 0px 10px",
                    display: "inline-block",
                  }}
                >
                  <Icon name="info-circle" size="24" />
                </div>
                <Tooltip id="senior_service_owner" style={{ width: "40vw" }} />
              </div>
              <div className="col-md-4">
                <div style={{ flexGrow: "1", height: "40px" }}>
                  <div
                    style={{
                      display: "flex",
                      flexGrow: "1",
                    }}
                  >
                    <input
                      type="text"
                      name="managed_by"
                      id="search-bar"
                      className="search-bar"
                      autoComplete="off"
                      value={
                        readOnly
                          ? tempData.managed_by.displayValue
                          : ownershipdata?.managed_by.displayValue
                      }
                      disabled={!readOnly}
                      onChange={(e) => {
                        handleChange(e, "managed_by");
                      }}
                      style={{
                        width: "86%",
                        flexGrow: "1",
                        borderTopRightRadius: "0px",
                        borderBottomRightRadius: "0px",
                        borderRight: "none",
                      }}
                    ></input>
                    <div
                      id="search-icon"
                      className="search-icon"
                      name="managed_by"
                      style={{
                        backgroundColor: !readOnly
                          ? "rgba(211, 211, 211, 0.6)"
                          : "#ffffff",
                        padding: "5px",
                        border: "1px solid #ccc",
                        cursor: readOnly ? "default" : "not-allowed",
                        textAlign: "center",
                        borderRadius: "0px 5px 5px 0px",
                        flexGrow: "1",
                        borderLeft: "none",
                      }}
                    >
                      {dropdownStates.managed_by ? (
                        ownershipdata?.managed_by?.user_status == "Inactive" ? (
                          <>
                            <FaUserAltSlash
                              data-tooltip-id="inactive_user"
                              data-tooltip-content="The user is inactive."
                              data-tooltip-place="top-start"
                              size={20}
                              title="Inactive User"
                              // data-tip="Inactive User"
                              style={{
                                color: "#FF6347", // Tomato red
                                verticalAlign: "sub",
                              }}
                            />
                            <Tooltip id="inactive_user" />
                          </>
                        ) : null
                      ) : (
                        <Icon
                          name="times-circle"
                          size="20"
                          id="managed_by"
                          onClick={handleCrossClick}
                          style={{
                            verticalAlign: "-4px",
                            cursor: readOnly ? "pointer" : "not-allowed",
                          }}
                        />
                      )}
                    </div>
                  </div>
                  <div
                    name="managed_by"
                    className="suggestion-container3"
                    style={{
                      flexGrow: "1",
                      maxHeight: "100px",
                      backgroundColor: "white",
                      zIndex: 10,
                      position: "relative",
                      display: "none",
                      borderTopLeftRadius: "0px",
                      overflowY: "auto",
                      backgroundColor: "white",
                      marginLeft: "5px",
                    }}
                  >
                    <div className="suggestion-item">
                      {" "}
                      {isLoading ? <LoadingIndicator></LoadingIndicator> : null}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="row"
              style={{
                overflow: "visible",
                height: "40px",
                borderBottom: "none",
              }}
            >
              <div className="col-md-8">
                Engineering Manager :
                <div
                  data-tooltip-id="engineering_manager"
                  data-tooltip-content="The Engineering Manager may also serve as a Service Owner, promoting CMDB data governance and co-owning processes such as Change and Incident Management within the team."
                  data-tooltip-place="top-start"
                  style={{
                    margin: "7px 0px 0px 10px",
                    display: "inline-block",
                  }}
                >
                  <Icon name="info-circle" size="24" />
                </div>
                <Tooltip id="engineering_manager" style={{ width: "40vw" }} />
              </div>
              <div className="col-md-4">
                <div style={{ flexGrow: "1", height: "40px" }}>
                  <div
                    style={{
                      display: "flex",
                      flexGrow: "1",
                    }}
                  >
                    <input
                      type="text"
                      name="u_engineering_manager"
                      className="search-bar"
                      id="search-bar"
                      autoComplete="off"
                      value={
                        readOnly
                          ? tempData.u_engineering_manager.displayValue
                          : ownershipdata?.u_engineering_manager.displayValue
                      }
                      disabled={!readOnly}
                      onChange={(e) => {
                        handleChange(e, "u_engineering_manager");
                      }}
                      style={{
                        width: "86%",
                        flexGrow: "1",
                        borderTopRightRadius: "0px",
                        borderBottomRightRadius: "0px",
                        borderRight: "none",
                      }}
                    ></input>
                    <div
                      id="search-icon"
                      className="search-icon"
                      name="u_engineering_manager"
                      style={{
                        backgroundColor: !readOnly
                          ? "rgba(211, 211, 211, 0.6)"
                          : "#ffffff",
                        padding: "5px",
                        border: "1px solid #ccc",
                        textAlign: "center",
                        cursor: readOnly ? "default" : "not-allowed",
                        borderRadius: "0px 5px 5px 0px",
                        flexGrow: "1",
                        borderLeft: "none",
                      }}
                    >
                      {dropdownStates.u_engineering_manager ? (
                        ownershipdata?.u_engineering_manager?.user_status ==
                        "Inactive" ? (
                          <>
                            <FaUserAltSlash
                              data-tooltip-id="inactive_user"
                              data-tooltip-content="The user is inactive."
                              data-tooltip-place="top-start"
                              size={20}
                              title="Inactive User"
                              // data-tip="Inactive User"
                              style={{
                                color: "#FF6347", // Tomato red
                                verticalAlign: "sub",
                              }}
                            />
                            <Tooltip id="inactive_user" />
                          </>
                        ) : null
                      ) : (
                        <Icon
                          name="times-circle"
                          size="20"
                          id="u_engineering_manager"
                          onClick={handleCrossClick}
                          style={{
                            verticalAlign: "-4px",
                            cursor: readOnly ? "pointer" : "not-allowed",
                          }}
                        />
                      )}
                    </div>
                  </div>
                  <div
                    name="u_engineering_manager"
                    className="suggestion-container3"
                    style={{
                      flexGrow: "1",
                      maxHeight: "100px",
                      backgroundColor: "white",
                      zIndex: 10,
                      position: "relative",
                      display: "none",
                      borderTopLeftRadius: "0px",
                      overflowY: "auto",
                      backgroundColor: "white",
                      marginLeft: "5px",
                    }}
                  >
                    <div className="suggestion-item">
                      {" "}
                      {isLoading ? <LoadingIndicator></LoadingIndicator> : null}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="row"
              style={{
                overflow: "visible",
                height: "40px",
                borderBottom: "none",
              }}
            >
              <div className="col-md-8">
                Senior Engineering Manager :
                <div
                  data-tooltip-id="senior_engineering_manager"
                  data-tooltip-content="The Senior Engineering Manager has responsibilities similar to the EM but acts as the next escalation point if the EM is unavailable."
                  data-tooltip-place="top-start"
                  style={{
                    margin: "7px 0px 0px 10px",
                    display: "inline-block",
                  }}
                >
                  <Icon name="info-circle" size="24" />
                </div>
                <Tooltip
                  id="senior_engineering_manager"
                  style={{ width: "40vw" }}
                />
              </div>
              <div className="col-md-4">
                <div style={{ flexGrow: "1", height: "40px" }}>
                  <div
                    style={{
                      display: "flex",
                      flexGrow: "1",
                    }}
                  >
                    <input
                      type="text"
                      name="u_sr_engineering_manager"
                      className="search-bar"
                      id="search-bar"
                      autoComplete="off"
                      value={
                        readOnly
                          ? tempData.u_sr_engineering_manager.displayValue
                          : ownershipdata?.u_sr_engineering_manager.displayValue
                      }
                      disabled={!readOnly}
                      onChange={(e) => {
                        handleChange(e, "u_sr_engineering_manager");
                      }}
                      style={{
                        width: "86%",
                        flexGrow: "1",
                        borderTopRightRadius: "0px",
                        borderBottomRightRadius: "0px",
                        borderRight: "none",
                      }}
                    ></input>
                    <div
                      id="search-icon"
                      name="u_sr_engineering_manager"
                      className="search-icon"
                      style={{
                        backgroundColor: !readOnly
                          ? "rgba(211, 211, 211, 0.6)"
                          : "#ffffff",
                        padding: "5px",
                        border: "1px solid #ccc",
                        cursor: readOnly ? "default" : "not-allowed",
                        textAlign: "center",
                        borderRadius: "0px 5px 5px 0px",
                        flexGrow: "1",
                        borderLeft: "none",
                      }}
                    >
                      {dropdownStates.u_sr_engineering_manager ? (
                        ownershipdata?.u_sr_engineering_manager?.user_status ==
                        "Inactive" ? (
                          <>
                            <FaUserAltSlash
                              data-tooltip-id="inactive_user"
                              data-tooltip-content="The user is inactive."
                              data-tooltip-place="top-start"
                              size={20}
                              title="Inactive User"
                              // data-tip="Inactive User"
                              style={{
                                color: "#FF6347", // Tomato red
                                verticalAlign: "sub",
                              }}
                            />
                            <Tooltip id="inactive_user" />
                          </>
                        ) : null
                      ) : (
                        <Icon
                          name="times-circle"
                          size="20"
                          id="u_sr_engineering_manager"
                          onClick={handleCrossClick}
                          style={{
                            verticalAlign: "-4px",
                            cursor: readOnly ? "pointer" : "not-allowed",
                          }}
                        />
                      )}
                    </div>
                  </div>
                  <div
                    name="u_sr_engineering_manager"
                    className="suggestion-container3"
                    style={{
                      flexGrow: "1",
                      maxHeight: "100px",
                      backgroundColor: "white",
                      zIndex: 10,
                      position: "relative",
                      display: "none",
                      borderTopLeftRadius: "0px",
                      overflowY: "auto",
                      backgroundColor: "white",
                      marginLeft: "5px",
                    }}
                  >
                    <div className="suggestion-item">
                      {" "}
                      {isLoading ? <LoadingIndicator></LoadingIndicator> : null}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="row"
              style={{
                overflow: "visible",
                height: "40px",
                borderBottom: "none",
              }}
            >
              <div className="col-md-8">
                Engineering Director :
                <div
                  data-tooltip-id="engineering_director"
                  data-tooltip-content="The Engineering Director (ED) oversees multiple Engineering Managers, sets technical guardrails, drives engineering consistency, manages the technical improvement roadmap, ensures cost-effectiveness, and adopts industry-standard practices."
                  data-tooltip-place="top-start"
                  style={{
                    margin: "7px 0px 0px 10px",
                    display: "inline-block",
                  }}
                >
                  <Icon name="info-circle" size="24" />
                </div>
                <Tooltip id="engineering_director" style={{ width: "40vw" }} />
              </div>
              <div className="col-md-4">
                <div style={{ flexGrow: "1", height: "40px" }}>
                  <div
                    style={{
                      display: "flex",
                      flexGrow: "1",
                    }}
                  >
                    <input
                      type="text"
                      name="u_engineering_director"
                      className="search-bar"
                      id="search-bar"
                      autoComplete="off"
                      value={
                        readOnly
                          ? tempData.u_engineering_director.displayValue
                          : ownershipdata?.u_engineering_director.displayValue
                      }
                      disabled={!readOnly}
                      onChange={(e) => {
                        handleChange(e, "u_engineering_director");
                      }}
                      style={{
                        width: "86%",
                        flexGrow: "1",
                        borderTopRightRadius: "0px",
                        borderBottomRightRadius: "0px",
                        borderRight: "none",
                      }}
                    ></input>
                    <div
                      id="search-icon"
                      className="search-icon"
                      name="u_engineering_director"
                      style={{
                        backgroundColor: !readOnly
                          ? "rgba(211, 211, 211, 0.6)"
                          : "#ffffff",
                        padding: "5px",
                        border: "1px solid #ccc",
                        textAlign: "center",
                        cursor: readOnly ? "default" : "not-allowed",
                        borderRadius: "0px 5px 5px 0px",
                        flexGrow: "1",
                        borderLeft: "none",
                      }}
                    >
                      {dropdownStates.u_engineering_director ? (
                        ownershipdata?.u_engineering_director?.user_status ==
                        "Inactive" ? (
                          <>
                            <FaUserAltSlash
                              data-tooltip-id="inactive_user"
                              data-tooltip-content="The user is inactive."
                              data-tooltip-place="top-start"
                              size={20}
                              title="Inactive User"
                              // data-tip="Inactive User"
                              style={{
                                color: "#FF6347", // Tomato red
                                verticalAlign: "sub",
                              }}
                            />
                            <Tooltip id="inactive_user" />
                          </>
                        ) : null
                      ) : (
                        <Icon
                          name="times-circle"
                          size="20"
                          id="u_engineering_director"
                          onClick={handleCrossClick}
                          style={{
                            verticalAlign: "-4px",
                            cursor: readOnly ? "pointer" : "not-allowed",
                          }}
                        />
                      )}
                    </div>
                  </div>
                  <div
                    name="u_engineering_director"
                    className="suggestion-container3"
                    style={{
                      flexGrow: "1",
                      maxHeight: "100px",
                      backgroundColor: "white",
                      zIndex: 10,
                      position: "relative",
                      display: "none",
                      borderTopLeftRadius: "0px",
                      overflowY: "auto",
                      backgroundColor: "white",
                      marginLeft: "5px",
                    }}
                  >
                    <div className="suggestion-item">
                      {" "}
                      {isLoading ? <LoadingIndicator></LoadingIndicator> : null}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="row"
              style={{
                overflow: "visible",
                height: "40px",
                borderBottom: "none",
              }}
            >
              <div className="col-md-8">MVC Value:</div>

              <div className="col-md-4">
                <div style={{ flexGrow: "1", height: "40px" }}>
                  <div
                    style={{
                      display: "flex",
                      flexGrow: "1",
                    }}
                  >
                    <input
                      type="text"
                      name="u_engineering_director"
                      className="search-bar"
                      id="search-bar"
                      autoComplete="off"
                      value={ownershipdata?.u_mvc?.displayValue ?? ""}
                      disabled={true}
                      // onChange={(e) => {
                      //   handleChange(e, "u_engineering_director");
                      // }}
                      style={{
                        width: "86%",
                        flexGrow: "1",
                        borderTopRightRadius: "0px",
                        borderBottomRightRadius: "0px",
                        borderRight: "none",
                      }}
                    ></input>
                    <div
                      id="search-icon"
                      className="search-icon"
                      name="u_engineering_director"
                      style={{
                        backgroundColor: "rgba(211, 211, 211, 0.6)",
                        padding: "5px",
                        border: "1px solid #ccc",
                        textAlign: "center",
                        cursor: "not-allowed",
                        borderRadius: "0px 5px 5px 0px",
                        flexGrow: "1",
                        borderLeft: "none",
                      }}
                    ></div>
                  </div>
                </div>
              </div>
            </div>
            <div
              style={{
                float: "left",
                margin: "10px",
                marginLeft: "20px",
                marginTop: "20px",
                fontSize: "0.8em",
              }}
            >
              {isciadmingrp && !ownershipTooltip.includes("retired") && (
                <span>
                  Note: Please search for a name and select from dropdown
                </span>
              )}
            </div>
            {isciadmingrp && !ownershipTooltip.includes("retired") && (
              <div
                style={{
                  margin: "10px",
                  float: "right",
                  height: "40px",
                  marginRight: "20px",
                }}
              >
                <Button
                  appearance="alt"
                  fit="medium"
                  id="primary"
                  justifyItems="center"
                  label="Edit"
                  name="primary"
                  onClick={function noRefCheck() {
                    setTempData({ ...ownershipdata });
                    setReadOnly(true);
                    setshowbuttons(true);
                  }}
                  title="Button"
                  variant="filled"
                  style={{
                    width: "80px",
                    display: showbuttons ? "none" : "block",
                  }}
                />
                {showbuttons && (
                  <div style={{ display: "inline" }}>
                    <Button
                      appearance="alt"
                      fit="medium"
                      id="primary"
                      justifyItems="center"
                      label="Save"
                      name="primary"
                      onClick={function noRefCheck() {
                        handleSaveClick();
                      }}
                      title="Button"
                      variant="filled"
                      style={{ width: "80px", marginLeft: "10px" }}
                    />
                    <Button
                      appearance="alt"
                      fit="medium"
                      id="primary"
                      justifyItems="center"
                      label="Discard"
                      name="primary"
                      onClick={function noRefCheck() {
                        handleDiscardClick();
                        setshowbuttons(false);
                        setReadOnly(false);
                      }}
                      title="Button"
                      variant="filled"
                      style={{ width: "80px", marginLeft: "10px" }}
                    />
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
        <div className="row">
          <div className="accordion2">
            <div
              style={{
                display: "inline-block",
                verticalAlign: "-0.7em",
              }}
            >
              Support Group
            </div>

            <div
              style={{
                float: "right",
                display: "flex",
              }}
            >
              <Button
                ref={inputRef}
                appearance="alt"
                fit="medium"
                id="primary"
                justifyItems="center"
                label={isSupportGroupViewOpen ? "Hide" : "View"}
                name="primary"
                onClick={(e) => {
                  if (certifyapplicationName != "" || isSupportGroupViewOpen) {
                    handleAccordionclick(e, "accordion2", "panel2");
                    setIsSupportGroupViewOpen(!isSupportGroupViewOpen);
                  } else {
                    toast.error("Please select an application", {
                      position: "top-center",
                      autoClose: 3000,
                      hideProgressBar: false,
                      closeOnClick: true,
                      pauseOnHover: true,
                      draggable: true,
                      progress: undefined,
                      theme: "light",
                    });
                  }
                }}
                title="Button"
                variant="filled"
                style={{ width: "80px" }}
              />
              <div
                data-tooltip-id="info1"
                // data-tooltip-hidden={certifyapplicationName ? false : true}
                data-tooltip-content={
                  "Support Group Information for the Application"
                }
                data-tooltip-place="top-end"
                style={{
                  margin: "7px 0px 0px 10px",
                  display: "inline-block",
                }}
              >
                <Icon name="info-circle" size="24" />
              </div>
              <Tooltip id="info1" />
            </div>
          </div>
          <div className="panel2">
            <div
              className="row"
              style={{
                overflow: "visible",
                height: "40px",
                borderBottom: "none",
              }}
            >
              <div className="col-md-8">
                Support Group :
                {/* <div
                  data-tooltip-id="service_owner"
                  data-tooltip-content="The Service Owner is the main contact for the applications owned, ensuring the CMDB is accurate for key stakeholders. The SO reviews and approves changes, manages major incidents, and coordinates with functional teams like Vendor, Risk, and Audits."
                  data-tooltip-place="top-start"
                  style={{
                    margin: "7px 0px 0px 10px",
                    display: "inline-block",
                  }}
                 >
                  <Icon name="info-circle" size="24" />
                </div> */}
                {/* <Tooltip id="service_owner" style={{ width: "40vw" }} /> */}
              </div>
              <div className="col-md-4">
                <div style={{ flexGrow: "1", height: "40px" }}>
                  <div
                    style={{
                      display: "flex",
                      flexGrow: "1",
                    }}
                  >
                    <input
                      type="text"
                      name="support_group"
                      id="search-bar"
                      className="search-bar"
                      autoComplete="off"
                      value={
                        !supportGroupreadOnly2
                          ? supportGroupUpdatedData?.support_group?.displayValue
                          : ownershipdata?.support_group?.displayValue
                      }
                      disabled={supportGroupreadOnly2}
                      onChange={(e) => {
                        handleSupportGroupChange(e);
                      }}
                      style={{
                        width: "86%",
                        flexGrow: "1",
                        borderTopRightRadius: "0px",
                        borderBottomRightRadius: "0px",
                        borderRight: "none",
                      }}
                    ></input>
                    <div
                      id="search-icon"
                      className="search-icon"
                      name="support_group"
                      style={{
                        backgroundColor: supportGroupreadOnly2
                          ? "rgba(211, 211, 211, 0.6)"
                          : "#ffffff",
                        padding: "5px",
                        cursor: !supportGroupreadOnly2
                          ? "default"
                          : "not-allowed",
                        border: "1px solid #ccc",
                        textAlign: "center",
                        borderRadius: "0px 5px 5px 0px",
                        flexGrow: "1",
                        borderLeft: "none",
                      }}
                    >
                      {!dropdownStates.support_group ? (
                        <Icon
                          name="times-circle"
                          size="20"
                          id="support_group"
                          onClick={handleCrossClick}
                          style={{
                            verticalAlign: "-4px",
                            cursor: supportGroupreadOnly2
                              ? "not-allowed"
                              : "pointer", // Corrected cursor style
                          }}
                        />
                      ) : null}
                    </div>
                  </div>
                  <div
                    name="support_group"
                    className="suggestion-container3"
                    style={{
                      flexGrow: "1",
                      maxHeight: "100px",
                      backgroundColor: "white",
                      zIndex: 10,
                      position: "relative",
                      display: "none",
                      borderTopLeftRadius: "0px",
                      overflowY: "auto",
                      backgroundColor: "white",
                      marginLeft: "5px",
                    }}
                  >
                    <div className="suggestion-item">
                      {" "}
                      {isLoading ? <LoadingIndicator></LoadingIndicator> : null}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="row"
              style={{
                overflow: "visible",
                height: "40px",
                borderBottom: "none",
              }}
            >
              <div className="col-md-8">
                Service Request Support Group :
                {/* <div
                  data-tooltip-id="senior_service_owner"
                  data-tooltip-content="The Senior Service Owner has similar responsibilities to the SO but acts as the next escalation point if the SO is unavailable."
                  data-tooltip-place="top-start"
                  style={{
                    margin: "7px 0px 0px 10px",
                    display: "inline-block",
                  }}
                >
                  <Icon name="info-circle" size="24" />
                </div>
                <Tooltip id="senior_service_owner" style={{ width: "40vw" }} /> */}
              </div>
              <div className="col-md-4">
                <div style={{ flexGrow: "1", height: "40px" }}>
                  <div
                    style={{
                      display: "flex",
                      flexGrow: "1",
                    }}
                  >
                    <input
                      type="text"
                      name="u_service_request_support_group"
                      id="search-bar"
                      className="search-bar"
                      autoComplete="off"
                      value={
                        !supportGroupreadOnly2
                          ? supportGroupUpdatedData
                              ?.u_service_request_support_group?.displayValue
                          : ownershipdata?.u_service_request_support_group
                              ?.displayValue
                      }
                      disabled={supportGroupreadOnly2}
                      onChange={(e) => {
                        handleSupportGroupChange(e);
                      }}
                      style={{
                        width: "86%",
                        flexGrow: "1",
                        borderTopRightRadius: "0px",
                        borderBottomRightRadius: "0px",
                        borderRight: "none",
                      }}
                    ></input>
                    <div
                      id="search-icon"
                      className="search-icon"
                      name="u_service_request_support_group"
                      style={{
                        backgroundColor: supportGroupreadOnly2
                          ? "rgba(211, 211, 211, 0.6)"
                          : "#ffffff",
                        padding: "5px",
                        cursor: !supportGroupreadOnly2
                          ? "default"
                          : "not-allowed",
                        border: "1px solid #ccc",
                        textAlign: "center",
                        borderRadius: "0px 5px 5px 0px",
                        flexGrow: "1",
                        borderLeft: "none",
                      }}
                    >
                      {!dropdownStates.u_service_request_support_group ? (
                        <Icon
                          name="times-circle"
                          size="20"
                          id="u_service_request_support_group"
                          onClick={handleCrossClick}
                          style={{
                            verticalAlign: "-4px",
                            cursor: supportGroupreadOnly2
                              ? "not-allowed"
                              : "pointer", // Corrected cursor style
                          }}
                        />
                      ) : null}
                    </div>
                  </div>
                  <div
                    name="u_service_request_support_group"
                    className="suggestion-container3"
                    style={{
                      flexGrow: "1",
                      maxHeight: "100px",
                      backgroundColor: "white",
                      zIndex: 10,
                      position: "relative",
                      display: "none",
                      borderTopLeftRadius: "0px",
                      overflowY: "auto",
                      backgroundColor: "white",
                      marginLeft: "5px",
                    }}
                  >
                    <div className="suggestion-item">
                      {" "}
                      {isLoading ? <LoadingIndicator></LoadingIndicator> : null}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="row"
              style={{
                overflow: "visible",
                height: "40px",
                borderBottom: "none",
              }}
            >
              <div className="col-md-8">
                Support Group L2 :
                {/* <div
                  data-tooltip-id="engineering_manager"
                  data-tooltip-content="The Engineering Manager may also serve as a Service Owner, promoting CMDB data governance and co-owning processes such as Change and Incident Management within the team."
                  data-tooltip-place="top-start"
                  style={{
                    margin: "7px 0px 0px 10px",
                    display: "inline-block",
                  }}
                >
                  <Icon name="info-circle" size="24" />
                </div>
                <Tooltip id="engineering_manager" style={{ width: "40vw" }} /> */}
              </div>
              <div className="col-md-4">
                <div style={{ flexGrow: "1", height: "40px" }}>
                  <div
                    style={{
                      display: "flex",
                      flexGrow: "1",
                    }}
                  >
                    <input
                      type="text"
                      name="u_support_group_l2"
                      id="search-bar"
                      className="search-bar"
                      autoComplete="off"
                      required
                      value={
                        !supportGroupreadOnly2
                          ? supportGroupUpdatedData?.u_support_group_l2
                              ?.displayValue
                          : ownershipdata?.u_support_group_l2?.displayValue
                      }
                      disabled={supportGroupreadOnly2}
                      onChange={(e) => {
                        handleSupportGroupChange(e);
                      }}
                      style={{
                        width: "86%",
                        flexGrow: "1",
                        borderTopRightRadius: "0px",
                        borderBottomRightRadius: "0px",
                        borderRight: "none",
                      }}
                    ></input>
                    <div
                      id="search-icon"
                      className="search-icon"
                      name="u_support_group_l2"
                      style={{
                        backgroundColor: supportGroupreadOnly2
                          ? "rgba(211, 211, 211, 0.6)"
                          : "#ffffff",
                        padding: "5px",
                        cursor: !supportGroupreadOnly2
                          ? "default"
                          : "not-allowed",
                        border: "1px solid #ccc",
                        textAlign: "center",
                        borderRadius: "0px 5px 5px 0px",
                        flexGrow: "1",
                        borderLeft: "none",
                      }}
                    >
                      {!dropdownStates.u_support_group_l2 ? (
                        <Icon
                          name="times-circle"
                          size="20"
                          id="u_support_group_l2"
                          onClick={handleCrossClick}
                          style={{
                            verticalAlign: "-4px",
                            cursor: supportGroupreadOnly2
                              ? "not-allowed"
                              : "pointer", // Corrected cursor style
                          }}
                        />
                      ) : null}
                    </div>
                  </div>
                  <div
                    name="u_support_group_l2"
                    className="suggestion-container3"
                    style={{
                      flexGrow: "1",
                      maxHeight: "100px",
                      backgroundColor: "white",
                      zIndex: 10,
                      position: "relative",
                      display: "none",
                      borderTopLeftRadius: "0px",
                      overflowY: "auto",
                      backgroundColor: "white",
                      marginLeft: "5px",
                    }}
                  >
                    <div className="suggestion-item">
                      {" "}
                      {isLoading ? <LoadingIndicator></LoadingIndicator> : null}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="row"
              style={{
                overflow: "visible",
                height: "40px",
                borderBottom: "none",
              }}
            >
              <div className="col-md-8">
                Support Group L3 :
                {/* <div
                  data-tooltip-id="senior_engineering_manager"
                  data-tooltip-content="The Senior Engineering Manager has responsibilities similar to the EM but acts as the next escalation point if the EM is unavailable."
                  data-tooltip-place="top-start"
                  style={{
                    margin: "7px 0px 0px 10px",
                    display: "inline-block",
                  }}
                >
                  <Icon name="info-circle" size="24" />
                </div>
                <Tooltip
                  id="senior_engineering_manager"
                  style={{ width: "40vw" }}
                /> */}
              </div>
              <div className="col-md-4">
                <div style={{ flexGrow: "1", height: "40px" }}>
                  <div
                    style={{
                      display: "flex",
                      flexGrow: "1",
                    }}
                  >
                    <input
                      type="text"
                      name="u_support_group_l3"
                      id="search-bar"
                      className="search-bar"
                      autoComplete="off"
                      required
                      value={
                        !supportGroupreadOnly2
                          ? supportGroupUpdatedData?.u_support_group_l3
                              ?.displayValue
                          : ownershipdata?.u_support_group_l3?.displayValue
                      }
                      disabled={supportGroupreadOnly2}
                      onChange={(e) => {
                        handleSupportGroupChange(e);
                      }}
                      style={{
                        width: "86%",
                        flexGrow: "1",
                        borderTopRightRadius: "0px",
                        borderBottomRightRadius: "0px",
                        borderRight: "none",
                      }}
                    ></input>
                    <div
                      id="search-icon"
                      className="search-icon"
                      name="u_support_group_l3"
                      style={{
                        backgroundColor: supportGroupreadOnly2
                          ? "rgba(211, 211, 211, 0.6)"
                          : "#ffffff",
                        padding: "5px",
                        cursor: !supportGroupreadOnly2
                          ? "default"
                          : "not-allowed",
                        border: "1px solid #ccc",
                        textAlign: "center",
                        borderRadius: "0px 5px 5px 0px",
                        flexGrow: "1",
                        borderLeft: "none",
                      }}
                    >
                      {!dropdownStates.u_support_group_l3 ? (
                        <Icon
                          name="times-circle"
                          size="20"
                          id="u_support_group_l3"
                          onClick={handleCrossClick}
                          style={{
                            verticalAlign: "-4px",
                            cursor: supportGroupreadOnly2
                              ? "not-allowed"
                              : "pointer", // Corrected cursor style
                          }}
                        />
                      ) : null}
                    </div>
                  </div>
                  <div
                    name="u_support_group_l3"
                    className="suggestion-container3"
                    style={{
                      flexGrow: "1",
                      maxHeight: "100px",
                      backgroundColor: "white",
                      zIndex: 10,
                      position: "relative",
                      display: "none",
                      borderTopLeftRadius: "0px",
                      overflowY: "auto",
                      backgroundColor: "white",
                      marginLeft: "5px",
                    }}
                  >
                    <div className="suggestion-item">
                      {" "}
                      {isLoading ? <LoadingIndicator></LoadingIndicator> : null}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="row"
              style={{
                overflow: "visible",
                height: "40px",
                borderBottom: "none",
              }}
            >
              <div className="col-md-8">
                Support Group L4 :
                {/* <div
                  data-tooltip-id="engineering_director"
                  data-tooltip-content="The Engineering Director (ED) oversees multiple Engineering Managers, sets technical guardrails, drives engineering consistency, manages the technical improvement roadmap, ensures cost-effectiveness, and adopts industry-standard practices."
                  data-tooltip-place="top-start"
                  style={{
                    margin: "7px 0px 0px 10px",
                    display: "inline-block",
                  }}
                >
                  <Icon name="info-circle" size="24" />
                </div>
                <Tooltip id="engineering_director" style={{ width: "40vw" }} /> */}
              </div>
              <div className="col-md-4">
                <div style={{ flexGrow: "1", height: "40px" }}>
                  <div
                    style={{
                      display: "flex",
                      flexGrow: "1",
                    }}
                  >
                    <input
                      type="text"
                      name="u_support_group_l4"
                      id="search-bar"
                      className="search-bar"
                      autoComplete="off"
                      required
                      value={
                        !supportGroupreadOnly2
                          ? supportGroupUpdatedData?.u_support_group_l4
                              ?.displayValue
                          : ownershipdata?.u_support_group_l4?.displayValue
                      }
                      disabled={supportGroupreadOnly2}
                      onChange={(e) => {
                        handleSupportGroupChange(e);
                      }}
                      style={{
                        width: "86%",
                        flexGrow: "1",
                        borderTopRightRadius: "0px",
                        borderBottomRightRadius: "0px",
                        borderRight: "none",
                      }}
                    ></input>
                    <div
                      id="search-icon"
                      className="search-icon"
                      name="u_support_group_l4"
                      style={{
                        backgroundColor: supportGroupreadOnly2
                          ? "rgba(211, 211, 211, 0.6)"
                          : "#ffffff",
                        padding: "5px",
                        cursor: !supportGroupreadOnly2
                          ? "default"
                          : "not-allowed",
                        border: "1px solid #ccc",
                        textAlign: "center",
                        borderRadius: "0px 5px 5px 0px",
                        flexGrow: "1",
                        borderLeft: "none",
                      }}
                    >
                      {!dropdownStates.u_support_group_l4 ? (
                        <Icon
                          name="times-circle"
                          size="20"
                          id="u_support_group_l4"
                          onClick={handleCrossClick}
                          style={{
                            verticalAlign: "-4px",
                            cursor: supportGroupreadOnly2
                              ? "not-allowed"
                              : "pointer", // Corrected cursor style
                          }}
                        />
                      ) : null}
                    </div>
                  </div>
                  <div
                    name="u_support_group_l4"
                    className="suggestion-container3"
                    style={{
                      flexGrow: "1",
                      maxHeight: "100px",
                      backgroundColor: "white",
                      zIndex: 10,
                      position: "relative",
                      display: "none",
                      borderTopLeftRadius: "0px",
                      overflowY: "auto",
                      backgroundColor: "white",
                      marginLeft: "5px",
                    }}
                  >
                    <div className="suggestion-item">
                      {" "}
                      {isLoading ? <LoadingIndicator></LoadingIndicator> : null}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              style={{
                float: "left",
                margin: "10px",
                marginLeft: "20px",
                marginTop: "20px",
                fontSize: "0.8em",
              }}
            >
              {isciadmingrp && !ownershipTooltip.includes("retired") && (
                <span>
                  Note: Please search for a name and select from dropdown
                </span>
              )}
            </div>
            {isciadmingrp && !ownershipTooltip.includes("retired") && (
              <div
                style={{
                  margin: "10px",
                  float: "right",
                  height: "40px",
                  marginRight: "20px",
                }}
              >
                <Button
                  appearance="alt"
                  fit="medium"
                  id="primary"
                  justifyItems="center"
                  label="Edit"
                  name="primary"
                  onClick={function noRefCheck() {
                    const {
                      vendor,
                      u_environment,
                      u_ci_admin_group,
                      operational_status,
                      support_group,
                      u_application_type,
                      u_service_request_support_group,
                      u_support_group_l2,
                      u_support_group_l3,
                      u_support_group_l4,
                      sys_id,
                    } = ownershipdata;
                    setSupportGroupUpdatedData({
                      vendor,
                      u_environment,
                      u_ci_admin_group,
                      operational_status,
                      support_group,
                      u_service_request_support_group,
                      u_support_group_l2,
                      u_support_group_l3,
                      u_support_group_l4,
                      u_application_type,
                      sys_id,
                    });
                    setSupportGroupreadOnly2(false);
                    setShowSupportGroupButtons2(true);
                  }}
                  title="Button"
                  variant="filled"
                  style={{
                    width: "80px",
                    display: showSupportGroupButtons2 ? "none" : "block",
                  }}
                />
                {showSupportGroupButtons2 && (
                  <div style={{ display: "inline" }}>
                    <Button
                      appearance="alt"
                      fit="medium"
                      id="primary"
                      justifyItems="center"
                      label="Save"
                      name="primary"
                      onClick={async function noRefCheck() {
                        // handleSaveClick();
                        await toast.promise(handleSupportGroupSaveClick1(), {
                          pending: "Saving...",
                          success: "Saved successfully",
                          error: "Failed to save",
                        });

                        setShowSupportGroupButtons2(false);
                        setSupportGroupreadOnly2(true);
                      }}
                      title="Button"
                      variant="filled"
                      style={{ width: "80px", marginLeft: "10px" }}
                    />
                    <Button
                      appearance="alt"
                      fit="medium"
                      id="primary"
                      justifyItems="center"
                      label="Discard"
                      name="primary"
                      onClick={function noRefCheck() {
                        handleDiscardClick();
                        setSupportGroupreadOnly2(true);
                        setShowSupportGroupButtons2(false);
                      }}
                      title="Button"
                      variant="filled"
                      style={{ width: "80px", marginLeft: "10px" }}
                    />
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
        <div className="row">
          <div className="accordion1">
            <div
              style={{
                display: "inline-block",
                verticalAlign: "-0.7em",
              }}
            >
              Support Attributes
              <span
                style={{
                  marginLeft: "3px",
                }}
              ></span>
            </div>

            <div
              style={{
                float: "right",
                display: "flex",
              }}
            >
              <Button
                ref={inputRef}
                appearance="alt"
                fit="medium"
                id="primary"
                justifyItems="center"
                label={isViewOpen1 ? "Hide" : "View"}
                name="primary"
                onClick={(e) => {
                  if (certifyapplicationName != "" || isViewOpen1) {
                    handleAccordionclick(
                      e,
                      "accordion1",
                      "panel1",
                      isViewOpen1
                    );
                    setIsViewOpen1(!isViewOpen1);
                  } else {
                    toast.error("Please select an application", {
                      position: "top-center",
                      autoClose: 3000,
                      hideProgressBar: false,
                      closeOnClick: true,
                      pauseOnHover: true,
                      draggable: true,
                      progress: undefined,
                      theme: "light",
                    });
                  }
                }}
                title="Button"
                variant="filled"
                style={{ width: "80px" }}
              />

              <div
                data-tooltip-id="info1"
                // data-tooltip-hidden={certifyapplicationName ? false : true}
                data-tooltip-content={"Support Information for the Application"}
                data-tooltip-place="top-end"
                style={{
                  margin: "7px 0px 0px 10px",
                  display: "inline-block",
                }}
              >
                <Icon name="info-circle" size="24" />
              </div>
              <Tooltip id="info1" />
            </div>
          </div>

          <div className="panel1">
            <div
              className="row"
              style={{
                overflow: "visible",
                height: "40px",
                borderBottom: "none",
              }}
            >
              <div className="col-md-8">
                Operational Status :
                {/* <div
                  data-tooltip-id="service_owner"
                  data-tooltip-content="The Service Owner is the main contact for the applications owned, ensuring the CMDB is accurate for key stakeholders. The SO reviews and approves changes, manages major incidents, and coordinates with functional teams like Vendor, Risk, and Audits."
                  data-tooltip-place="top-start"
                  style={{
                    margin: "7px 0px 0px 10px",
                    display: "inline-block",
                  }}
                 >
                  <Icon name="info-circle" size="24" />
                </div>
                <Tooltip id="service_owner" style={{ width: "40vw" }} /> */}
              </div>
              <div className="col-md-4">
                <div style={{ flexGrow: "1", height: "40px" }}>
                  <div
                    style={{
                      display: "flex",
                      flexGrow: "1",
                    }}
                  >
                    {/* <input
                      type="text"
                      name="operational_status"
                      id="search-bar"
                      className="search-bar"
                      autoComplete="off"
                      required
                      onChange={(e) => {
                        handleSupportGroupChange(e, "operational_status");
                      }}
                      value={
                        supportGroupreadOnly
                          ? ownershipdata?.operational_status?.displayValue ??
                            ""
                          : supportGroupUpdatedData?.operational_status
                              ?.displayValue
                      }
                      disabled={supportGroupreadOnly}
                      style={{
                        width: "86%",
                        flexGrow: "1",
                      }}
                    ></input> */}

                    <select
                      // className="custom-select"
                      id="search-bar"
                      name="operational_status"
                      defaultValue=""
                      style={{
                        width: "86%",
                        flexGrow: "1",
                      }}
                      disabled={supportGroupreadOnly}
                      value={
                        supportGroupreadOnly
                          ? ownershipdata?.operational_status?.displayValue ??
                            ""
                          : supportGroupUpdatedData?.operational_status
                              ?.displayValue
                      }
                      // value={Object.values(operationalStatusOptions) || ""}
                      onChange={(e) => {
                        handleSupportGroupChange(e, "operational_status");
                      }}
                    >
                      <option value="" disabled hidden>
   
  </option>
                      {Object.keys(operationalStatusOptions).map(
                        (optionKey) => (
                          <option key={optionKey} value={optionKey}>
                            {optionKey}
                          </option>
                        )
                      )}
                    </select>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="row"
              style={{
                overflow: "visible",
                height: "40px",
                borderBottom: "none",
              }}
            >
              <div className="col-md-8">Application Type :</div>
              <div className="col-md-4">
                <div style={{ flexGrow: "1", height: "40px" }}>
                  <div
                    style={{
                      display: "flex",
                      flexGrow: "1",
                    }}
                  >
                    {/* <input
                      type="text"
                      name="u_ci_admin_group"
                      id="search-bar"
                      className="search-bar"
                      autoComplete="off"
                      onChange={(e) => {
                        handleSupportGroupChange(e, "u_ci_admin_group");
                      }}
                      value={
                        supportGroupreadOnly
                          ? ownershipdata?.u_ci_admin_group?.displayValue ?? ""
                          : supportGroupUpdatedData?.u_ci_admin_group
                              ?.displayValue
                      }
                      disabled={supportGroupreadOnly}
                      style={{
                        width: "86%",
                        flexGrow: "1",
                      }}
                    ></input> */}
                    <select
                      // className="custom-select"
                      id="search-bar"
                      name="u_application_type"
                      style={{
                        width: "86%",
                        flexGrow: "1",
                      }}
                      disabled={supportGroupreadOnly}
                      value={
                        supportGroupreadOnly
                          ? ownershipdata?.u_application_type?.displayValue ??
                            ""
                          : supportGroupUpdatedData?.u_application_type
                              ?.displayValue
                      }
                      // value={Object.values(operationalStatusOptions) || ""}
                      onChange={(e) => {
                        handleSupportGroupChange(e, "u_application_type");
                      }}
                    >
                                <option value="" disabled hidden>
   
   </option>
                      {["COTS", "COTS customized", "Bespoke"].map(
                        (optionKey) => (
                          <option key={optionKey} value={optionKey}>
                            {optionKey}
                          </option>
                        )
                      )}
                    </select>
                  </div>
                </div>
              </div>
            </div>

            <div
              className="row"
              style={{
                overflow: "visible",
                height: "40px",
                borderBottom: "none",
              }}
            >
              <div className="col-md-8">Approval Group :</div>
              <div className="col-md-4">
                <div style={{ flexGrow: "1", height: "40px" }}>
                  <div
                    style={{
                      display: "flex",
                      flexGrow: "1",
                    }}
                  >
                    <input
                      type="text"
                      name="change_control"
                      className="search-bar"
                      id="search-bar"
                      autoComplete="off"
                      onChange={(e) => {
                        handleSupportGroupChange(e, "change_control");
                      }}
                      value={
                        supportGroupreadOnly
                          ? ownershipdata?.change_control?.displayValue ?? ""
                          : supportGroupUpdatedData?.change_control
                              ?.displayValue
                      }
                      disabled={supportGroupreadOnly}
                      style={{
                        width: "86%",
                        flexGrow: "1",
                        borderTopRightRadius: "0px",
                        borderBottomRightRadius: "0px",
                        borderRight: "none",
                      }}
                    ></input>
                    <div
                      id="search-icon"
                      className="search-icon"
                      name="change_control"
                      style={{
                        backgroundColor: supportGroupreadOnly
                          ? "rgba(211, 211, 211, 0.6)"
                          : "#ffffff",
                        padding: "5px",
                        cursor: !supportGroupreadOnly
                          ? "default"
                          : "not-allowed",
                        border: "1px solid #ccc",
                        textAlign: "center",
                        borderRadius: "0px 5px 5px 0px",
                        flexGrow: "1",
                        borderLeft: "none",
                      }}
                    >
                      {dropdownStates?.change_control ? null : (
                        <Icon
                          name="times-circle"
                          size="20"
                          id="change_control"
                          onClick={handleCrossClick}
                          style={{
                            verticalAlign: "-4px",
                            cursor: supportGroupreadOnly
                              ? "not-allowed"
                              : "pointer", // Corrected cursor style
                          }}
                        />
                      )}
                    </div>
                  </div>
                  <div
                    name="change_control"
                    className="suggestion-container3"
                    style={{
                      flexGrow: "1",
                      maxHeight: "100px",
                      backgroundColor: "white",
                      zIndex: 10,
                      position: "relative",
                      display: "none",
                      borderTopLeftRadius: "0px",
                      overflowY: "auto",
                      backgroundColor: "white",
                      marginLeft: "5px",
                    }}
                  >
                    <div className="suggestion-item">
                      {" "}
                      {isLoading ? <LoadingIndicator></LoadingIndicator> : null}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="row"
              style={{
                overflow: "visible",
                height: "40px",
                borderBottom: "none",
              }}
            >
              <div className="col-md-8">Vendor :</div>
              <div className="col-md-4">
                <div style={{ flexGrow: "1", height: "40px" }}>
                  <div
                    style={{
                      display: "flex",
                      flexGrow: "1",
                    }}
                  >
                    <input
                      type="text"
                      name="vendor"
                      className="search-bar"
                      id="search-bar"
                      autoComplete="off"
                      onChange={(e) => {
                        handleSupportGroupChange(e, "vendor");
                      }}
                      value={
                        supportGroupreadOnly
                          ? ownershipdata?.vendor?.displayValue ?? ""
                          : supportGroupUpdatedData?.vendor
                              ?.displayValue
                      }
                      disabled={supportGroupreadOnly}
                      style={{
                        width: "86%",
                        flexGrow: "1",
                        borderTopRightRadius: "0px",
                        borderBottomRightRadius: "0px",
                        borderRight: "none",
                      }}
                    ></input>
                    <div
                      id="search-icon"
                      className="search-icon"
                      name="vendor"
                      style={{
                        backgroundColor: supportGroupreadOnly
                          ? "rgba(211, 211, 211, 0.6)"
                          : "#ffffff",
                        padding: "5px",
                        cursor: !supportGroupreadOnly
                          ? "default"
                          : "not-allowed",
                        border: "1px solid #ccc",
                        textAlign: "center",
                        borderRadius: "0px 5px 5px 0px",
                        flexGrow: "1",
                        borderLeft: "none",
                      }}
                    >
                      {dropdownStates?.vendor ? null : (
                        <Icon
                          name="times-circle"
                          size="20"
                          id="vendor"
                          onClick={handleCrossClick}
                          style={{
                            verticalAlign: "-4px",
                            cursor: supportGroupreadOnly
                              ? "not-allowed"
                              : "pointer", // Corrected cursor style
                          }}
                        />
                      )}
                    </div>
                  </div>
                  <div
                    name="vendor"
                    className="suggestion-container3"
                    style={{
                      flexGrow: "1",
                      maxHeight: "100px",
                      backgroundColor: "white",
                      zIndex: 10,
                      position: "relative",
                      display: "none",
                      borderTopLeftRadius: "0px",
                      overflowY: "auto",
                      backgroundColor: "white",
                      marginLeft: "5px",
                    }}
                  >
                    <div className="suggestion-item">
                      {" "}
                      {isLoading ? <LoadingIndicator></LoadingIndicator> : null}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="row"
              style={{
                overflow: "visible",
                height: "40px",
                borderBottom: "none",
              }}
            >
              <div className="col-md-8">Environment :</div>
              <div className="col-md-4">
                <div style={{ flexGrow: "1", height: "40px" }}>
                  <div
                    style={{
                      display: "flex",
                      flexGrow: "1",
                    }}
                  >
                    <input
                      type="text"
                      name="u_environment"
                      className="search-bar"
                      id="search-bar"
                      autoComplete="off"
                      // onChange={(e) => {
                      //   handleSupportGroupChange(e, "u_environment");
                      // }}
                      value={ownershipdata?.u_environment?.displayValue ?? ""}
                      disabled={true}
                      style={{
                        width: "86%",
                        flexGrow: "1",
                      }}
                    ></input>
                  </div>
                </div>
              </div>
            </div>

            {isciadmingrp && !ownershipTooltip.includes("retired") && (
              <div
                style={{
                  margin: "10px",
                  float: "right",
                  height: "40px",
                  marginRight: "20px",
                }}
              >
                <Button
                  appearance="alt"
                  fit="medium"
                  id="primary"
                  justifyItems="center"
                  label="Edit"
                  name="primary"
                  onClick={function noRefCheck() {
                    const {
                      vendor,
                      u_environment,
                      u_ci_admin_group,
                      operational_status,
                      sys_id,
                      u_application_type,
                      change_control
                    } = ownershipdata;
                    setSupportGroupUpdatedData({
                      vendor,
                      u_environment,
                      u_ci_admin_group,
                      operational_status,
                      sys_id,
                      change_control,
                      u_application_type,
                    });
                    setSupportGroupreadOnly(false);
                    setShowSupportGroupButtons(true);
                  }}
                  title="Button"
                  variant="filled"
                  style={{
                    width: "80px",
                    display: showSupportGroupButtons ? "none" : "block",
                  }}
                />
                {showSupportGroupButtons && (
                  <div style={{ display: "inline" }}>
                    <Button
                      appearance="alt"
                      fit="medium"
                      id="primary"
                      justifyItems="center"
                      label="Save"
                      name="primary"
                      onClick={async function noRefCheck() {
                        console.log(supportGroupUpdatedData);
                        await toast.promise(handleSupportGroupSaveClick(), {
                          pending: "Saving...",
                          success: "Saved successfully",
                          error: "Failed to save",
                        });

                        setShowSupportGroupButtons(false);
                        setSupportGroupreadOnly(true);
                      }}
                      title="Button"
                      variant="filled"
                      style={{ width: "80px", marginLeft: "10px" }}
                    />
                    <Button
                      appearance="alt"
                      fit="medium"
                      id="primary"
                      justifyItems="center"
                      label="Discard"
                      name="primary"
                      onClick={function noRefCheck() {
                        handleDiscardClick();
                        setShowSupportGroupButtons(false);
                        setSupportGroupreadOnly(true);
                      }}
                      title="Button"
                      variant="filled"
                      style={{ width: "80px", marginLeft: "10px" }}
                    />
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
      {/* <div style={{ marginTop: "10px", visibility: "hidden" }}>""</div> */}
      {/* <button onClick={()=>{
        navigate("/test")
      }}>
        Click me
      </button>
      <div>{tempData.owned_by?.value}</div>
      <div>
        <div>Values: {tempData.owned_by.displayValue}</div>
        <div>Values: {tempData.managed_by.displayValue}</div>
        <div>Values: {tempData.u_engineering_manager.displayValue}</div>
        <div>Values: {tempData.u_sr_engineering_manager.displayValue}</div>
        <div>Values: {tempData.u_engineering_director.displayValue}</div>
      </div>
      <div>
        <div>{ownershipdata?.sys_id}</div>
        <div>Email : {ownershipdata?.managed_by.email}</div>
        <div>{ownershipdata?.owned_by.displayValue}</div>
        <div>{ownershipdata?.managed_by.displayValue}</div>
        <div>{ownershipdata?.u_engineering_manager.displayValue}</div>
        <div>{ownershipdata?.u_sr_engineering_manager.displayValue}</div>
        <div>{ownershipdata?.u_engineering_director.displayValue}</div>
      </div> */}
    </div>
  );
}
export default Certification;
