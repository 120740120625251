// Importing necessary libraries and components
import logo from "./logo.svg";
import "./App.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Navbar from "./components/Navbar";
import Home from "./components/Home";
import ViewChanges from "./components/ViewChanges";
import ApproveChanges from "./components/ApproveChanges";
import ViewChangeData from "./components/ViewChangeData";
import { useState, useEffect, useContext, createContext } from "react";
import LoadingBar from "react-top-loading-bar";
import LandingPage1 from "./components/LandingPage1";
import Error404 from "./components/Error404";
import { callMsGraph } from "./graph";
import { LoadingIndicator } from "@anchor/react-components";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { AuthenticatedTemplate, UnauthenticatedTemplate, useIsAuthenticated, useMsal, useMsalAuthentication } from "@azure/msal-react";
import { TableContext } from "./context";
import { EventType } from "@azure/msal-browser";
import { InteractionType } from "@azure/msal-browser";
import { loginRequest } from "./authConfig";
import Modal from "./components/Modal";
import Footer from "./components/Footer";
import Joyride from "react-joyride";
import Certification from "./components/Certification";
import Maintainance from "./components/Maintainance";
import BulkUploadModal from "./components/BulkUploadModal";
import CommonModal from "./components/CommonModal";
import TabRefresh from "./components/TabRefresh";
import AssetExplorer from "./components/AssetExplorer";
import ServerSpecifications from "./components/ServerSpecifications";
import OnboardingPage from "./components/CIOnboarding/OnBoardingPage";
import CIOnboarding from "./components/CIOnboarding/CIOnboarding";


const steps = [
  // Each object in this array represents a step in the Joyride
  // target: The selector of the element the step should focus on
  // content: The content of the tooltip for the step
  // disableBeacon: If true, the beacon will be hidden for the step
  {
    target: "#selectRelationshipCategory",
    content: "Select the relationship category to view the relationships between app to servers or app to app.",
    disableBeacon: true,
  },
  {
    target: ".search-input",
    content: "Navigate to the search and look for the application. It will provide you with the list of applications.",
    disableBeacon: true,
  },
  {
    target: "#create-button",
    content:
      "Add the server to create a relationship between app and new server and app to app. To create you need to search for application first",
  },

  {
    target: ".viewchanges",
    content: "For requestor to view and track all changes (addition & deletion of relationships) proposed for approval.",
  },
  {
    target: ".approvechanges",
    content: "A view for application owner to approve changes (addition & deletion of relationships) requested by the requestor.",
  },
  {
    target: ".certify",
    content: "A view for application owner to certify the relationships for app to server, app to app and app ownership certification.",
  },
  {
    target: ".user-profile",
    content: "Click here to logout ",
  },
];

// Main App component
/**
 * App component that handles authentication, state management, and routing for the CMDB Portal.
 * 
 * @component
 * 
 * @returns {JSX.Element} The rendered App component.
 * 
 * @example
 * <App />
 * 
 * @description
 * This component uses MSAL for authentication and manages various state variables and context.
 * It includes handlers for opening and closing modals, a callback for the Joyride component,
 * and an effect hook for adding a script to the body when the component mounts.
 * 
 * @function
 * @name App
 * 
 * @requires useMsalAuthentication
 * @requires InteractionType
 * @requires useState
 * @requires useContext
 * @requires TableContext
 * @requires useIsAuthenticated
 * @requires useEffect
 * @requires AuthenticatedTemplate
 * @requires UnauthenticatedTemplate
 * @requires Modal
 * @requires BulkUploadModal
 * @requires BrowserRouter
 * @requires Joyride
 * @requires ToastContainer
 * @requires Navbar
 * @requires LoadingBar
 * @requires Routes
 * @requires Route
 * @requires Home
 * @requires ViewChanges
 * @requires ApproveChanges
 * @requires LandingPage1
 * @requires Certification
 * @requires ViewChangeData
 * @requires Error404
 * @requires Footer
 * @requires LoadingIndicator
 */
function App() {
  useMsalAuthentication(InteractionType.Redirect);

  // State variables and context
  const [progress, setProgress] = useState(0);
  // const { instance, accounts } = useMsal();
  const { graphData, setGraphData, fetchUserDetails,commondmodalopen, setcommondmodalopen,handleCommonModalClose } = useContext(TableContext);
  const [open, setOpen] = useState(false);
  const [bulkuploadmodalopen, setbulkuploadmodal] = useState(false);



  const [run, setRun] = useState(false);
  const isAuthenticated = useIsAuthenticated();
  

  // Handlers for opening and closing the modal
  const handleClose = () => {
    setOpen(false);
  };


  
  const handleBulkUploadOpen = () => {
    setbulkuploadmodal(true);
  };
  const handleBulkUploadClose = () => {
    setbulkuploadmodal(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };
 

  // Callback for the Joyride component
  const handleJoyrideCallback = (data) => {
    const { action, index, status, type } = data;
    console.log(action, index, status, type);
    // if(index==2 && action=="next"){
    // }
    if (action == "close") {
      setRun(false);
    }
    if (action == "reset") {
      setRun(false);
    }
  };

  // const getToken=async ()=>{
  //   const user= await graphData?.displayName
  //   const response =await fetch(`${process.env.REACT_APP_BACKEND_URI}/getToken`,{

  //   })
  //   const data=await response.json()
  //   localStorage.setItem("token",data)
  // }
  // useEffect(() => {
  //   getToken()
  // }, [])

  // Effect hook for adding a script to the body when the component mounts

  useEffect(() => {
    try {
      if (isAuthenticated) {
        const script = document.createElement("script");
        script.src =
          "https://maersk-tools.atlassian.net/s/d41d8cd98f00b204e9800998ecf8427e-T/tod1zk/b/5/c95134bc67d3a521bb3f4331beb9b804/_/download/batch/com.atlassian.jira.collector.plugin.jira-issue-collector-plugin:issuecollector/com.atlassian.jira.collector.plugin.jira-issue-collector-plugin:issuecollector.js?locale=en-US&collectorId=2374fbe1";
        script.async = true;
        // script.crossOrigin = "use-credentials";
        document.body.appendChild(script);
        return () => {
          document.body.removeChild(script);
        };
      }
    } catch (error) {
      console.log(error);
    }
  }, [graphData]);

  // Rendering the App component
  return (
    <div>
      <AuthenticatedTemplate>
        <TabRefresh />
        <Modal isOpen={open} onClose={handleClose} setRun={setRun} setOpen={setOpen}></Modal>
        <BulkUploadModal isOpen={bulkuploadmodalopen} onClose={handleBulkUploadClose}  setOpen={setbulkuploadmodal}></BulkUploadModal>
       

        <BrowserRouter>
          <Joyride
            steps={steps}
            continuous={true}
            showProgress={true}
            showSkipButton={true}
            run={run}
            disableCloseOnEsc={true}
            callback={handleJoyrideCallback}
            styles={{
              options: {
                arrowColor: "#87ceeb",
                backgroundColor: "#fff",
                primaryColor: "#42b0d5",
                textColor: "black",

                zIndex: 1000,
              },
            }}
          />
          <ToastContainer  position="top-center" // Set the position of the toast container
        autoClose={3000} // Automatically close toasts after 3 seconds
        hideProgressBar={false} // Display the progress bar
        closeOnClick={true} // Close toasts on click
        pauseOnHover={true} // Pause toast animation on hover
        draggable={true}
        closeButton={true} // Allow toasts to be dragged
        theme="light" // Use the light theme
         />

          <Navbar setRun={setRun} run={run} graphData={graphData} />

          <LoadingBar height={4} color="#0073AB" progress={progress} />
          <Routes>
            <Route
              exact
              path="/main"
              element={<Home setProgress={setProgress} graphData={graphData} setOpen={setOpen} setRun={setRun} run={run} setbulkuploadmodal={setbulkuploadmodal} />}
            />
            <Route exact path="/ViewChanges" element={<ViewChanges setProgress={setProgress} />} />
            <Route exact path="/ApproveChanges" element={<ApproveChanges setProgress={setProgress} />} />
            <Route exact path="/" element={<LandingPage1 setProgress={setProgress} />} />
            <Route exact path="/certify" element={<Certification setProgress={setProgress} />} />
            <Route exact path="/ViewChangeData" element={<ViewChangeData setProgress={setProgress} />} />

            <Route exact path="/AssetExplorer" element={<AssetExplorer setProgress={setProgress} />} />

            <Route path="/server-specifications" element={<ServerSpecifications />} />
            <Route path="/cionboarding" element={<CIOnboarding />} />




            <Route path="*" element={<Error404 />} />
          </Routes>
        </BrowserRouter>
        <Footer />
      </AuthenticatedTemplate>

      <UnauthenticatedTemplate>
        <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100vh" }}>
          <LoadingIndicator appearance="default" fit="large" id="testing" label="Signing In" orientation="vertical" variant="spinner" />
        </div>
      </UnauthenticatedTemplate>
    </div>
  );
}

export default App;
