import React, { useState, useContext, useEffect, useRef } from "react";
import { Button, Chip, Typography } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { TableContext } from "../../context";
import { toast } from "react-toastify";

import "../../App.css";
import "react-toastify/dist/ReactToastify.css";
import DynamicInput from "./DynamicInput";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { type } from "jquery";
import DynamicInputOwnership from "./DynamicInputOwnership";
import DynamicInputSupportGroup from "./DynamicInputSupportGroup";
import Tags from "./Tags";
import Tags1 from "./Tags1";
import OwningBusinessUnitInput from "./OwningBusinessUnitInput";
import Tags2 from "./Tags2";
import LocationInput from "./LocationInput";
import VendorInput from "./VendorInput";

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

function CIOnboarding() {
  const query = useQuery();
  const location = useLocation();
  const navigate = useNavigate();

  const {
    selectedSuggestion,
    setSelectedSuggestion,
    fetchWithTimeout,
    graphData,
    getToken,
    fetchUserDetails,
    token,
  } = useContext(TableContext);
  const [applicationName, setapplicationName] = useState(
    query.get("applicationName")
  );
  const [suggestionSelected, setSuggestionSelected] = useState(true);
  const inputRef = useRef(null);
  const [searchbarName, setSearchbarName] = useState(
    applicationName ? applicationName : ""
  );
  const [applicationName1, setapplicationName1] = useState("");
  const [arrowStatus, setArrowStatus] = useState(false);
  const [isReadOnly, setIsReadOnly] = useState(true);
  const [data, setData] = useState({});
  const [updateddata, setupdatedData] = useState({});

  const [loading, setLoading] = useState(false);
  const [isciadmingrp, setisciadmingrp] = useState(false);

  useEffect(() => {
    if (graphData && token) {
      getToken();
    }
  }, [graphData, token]);

  useEffect(() => {
    const values = [
      data?.managed_by?.displayValue,
      data?.owned_by?.displayValue,
      data?.u_engineering_manager?.displayValue,
      data?.u_sr_engineering_manager?.displayValue,
      data?.u_engineering_director?.displayValue,
    ];
    console.log("values", values);
    setisciadmingrp(values.includes(graphData?.displayName));
    // //console.log("isciadmingrp", isciadmingrp);
  }, [data, graphData?.displayName]);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const response = await fetchWithTimeout(
          `${
            process.env.REACT_APP_BACKEND_URI
          }/get_app_boarding_data?applicationName=${encodeURIComponent(
            applicationName
          )}`,
          {
            method: "GET",
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );
        const data = await response.json();
        if (typeof data === "object") {
          if (data.message === "Exception: Application Record Not Found") {
            setData([]);
            console.error("Application Record not found");
            toast.error("Application Record Not Found", {
              position: "top-center",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            });
          } else {
            setData(data);
          }
        } else {
          console.error("Error fetching data:", data);
          toast.info("Search for the application to view the data", {
            position: "top-center",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });

          setData([]);
        }

        // navigate(location.pathname, { replace: true });
        //   setapplicationName(null);
      } catch (error) {
        console.error("Error fetching data:", error);
        toast.error("An error occured while fetching data", {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      } finally {
        setLoading(false);
        setSelectedSuggestion(true);
        setapplicationName1(applicationName);
        navigate(location.pathname, { replace: true });
        setapplicationName(null);
      }
    };

    const checkTokenAndFetchData = async () => {
      const token = localStorage.getItem("token");
      if (!token) {
        // Wait for the token to be set
        const interval = setInterval(() => {
          const newToken = localStorage.getItem("token");
          if (newToken) {
            clearInterval(interval);
            fetchData();
          }
        }, 100); // Check every 100ms
      } else {
        fetchData();
      }
    };

    if (graphData?.displayName !== undefined && applicationName) {
      console.log("fetching data");
      checkTokenAndFetchData();
    }
  }, [graphData?.displayName]);

  const validateFields = (fields) => {
    console.log("fields", fields?.displayName);
    return fields.every((field) => field?.displayName);
  };

  const handleApplyChanges = async () => {
    const {
      u_application_category,
      busines_criticality,
      u_application_hosting_env,
      owned_by,
      u_application_type,
      change_control,
      u_environment,
      name,
      operational_status,
      u_owning_business_unit,
      managed_by,
      service_classification,
      short_description,
      support_group,
      u_used_by_business_unit,
      u_description,
    } = updateddata;

    if (
      !validateUpdatedData([
        u_application_category,
        busines_criticality,
        u_application_hosting_env,
        owned_by,
        u_application_type,
        change_control,
        u_environment,
        name,
        operational_status,
        u_owning_business_unit,
        managed_by,
        service_classification,
        short_description,
        support_group,
        u_used_by_business_unit,
        u_description,
      ])
    ) {
      toast.error("Please fill in all the required fields", {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      return;
    }

    try {
      const response = await fetchWithTimeout(
        `${process.env.REACT_APP_BACKEND_URI}/update_app_boarding_data`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
          body: JSON.stringify(updateddata),
        }
      );
      const data = await response.json();

      if (response.ok) {
        await fetchData();
        setIsReadOnly(true);
        toast.success("Changes applied successfully", {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      } else {
        toast.error("Error applying changes. Please try again", {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
    } catch (error) {
      console.error("Error applying changes:", error);
      // toast.error("Error applying changes", {
      //   position: "top-center",
      //   autoClose: 3000,
      //   hideProgressBar: false,
      //   closeOnClick: true,
      //   pauseOnHover: true,
      //   draggable: true,
      //   progress: undefined,
      //   theme: "light",
      // });
    }
  };

  useEffect(() => {
    console.log("isReadOnly", isReadOnly);
  }, [isReadOnly]);

  console.log("data", data);
  const fetchData = async () => {
    // setLoading(true);
    try {
      const response = await fetchWithTimeout(
        `${
          process.env.REACT_APP_BACKEND_URI
        }/get_app_boarding_data?applicationName=${encodeURIComponent(
          applicationName1
        )}`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
            ContentType: "application/json",
          },
        }
      );
      const data = await response.json();
      if (typeof data === "object") {
        if (data.message === "Exception: Application Record Not Found") {
          setData([]);
          console.error("Application Record not found");
          toast.error("Application Record Not Found", {
            position: "top-center",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        } else {
          setData(data);
        }
      } else {
        console.error("Error fetching data:", data);
        toast.info("Search for the application to view the data", {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        setData([]);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      toast.error("An error occured while fetching data", {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    } finally {
      setLoading(false);
    }
  };
  const validateUpdatedData = (data) => {
    for (let field of data) {
      console.log("field", field.displayValue);
      if (field.displayValue.trim() === "") {
        return false;
      }
    }
    return true;
  };
    const handleSearchSubmit = async (e) => {
    e.preventDefault();
    if (isciadmingrp && !isReadOnly) {
      handleEditClick();
    }
    if (!applicationName1) {
      toast.error("Please enter the application name", {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      return;
    }
  
    if (selectedSuggestion) {
      console.log("selectedSuggestion", selectedSuggestion);
      setData({});
      setLoading(true);
      try {
        const response = await fetchWithTimeout(
          `${
            process.env.REACT_APP_BACKEND_URI
          }/get_app_boarding_data?applicationName=${encodeURIComponent(
            applicationName1
          )}`,
          {
            method: "GET",
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
              ContentType: "application/json",
            },
          }
        );
        const data = await response.json();
        if (typeof data === "object") {
          if (data.message === "Exception: Application Record Not Found") {
            setData([]);
            console.error("Application Record not found");
            toast.error("Application Record Not Found", {
              position: "top-center",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            });
          } else {
            setData(data);
          }
          setLoading(false);
        } else {
          console.error("Error fetching data:", data);
          setLoading(false);
          toast.info("Search for the application to view the data", {
            position: "top-center",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
  
          setData([]);
        }
      } catch (error) {
        console.error("Error during fetch operation:", error);
        setLoading(false);
        toast.error("An error occurred while fetching data", {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
    } else {
      toast.error("Please select an application from the drop down", {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  };

  const handleapplicationNameChange = (event) => {
    setSuggestionSelected(false);
    setSelectedSuggestion(false);
    setapplicationName1(event.target.value);
    setSearchbarName(event.target.value);
    setArrowStatus(false);
  };
  useEffect(() => {
    if (!suggestionSelected) {
      const getApplicationSuggestion = setTimeout(async () => {
        const searchBox = document.getElementById("application-name");
        const suggestionBox = document.getElementById("suggestion-box");
        const searchTerm = searchBox.value;
        if (searchTerm.length >= 3) {
          suggestionBox.innerHTML = "Loading...";
          suggestionBox.style.display = "block";
          searchBox.style.borderBottomLeftRadius = "0%";
          searchBox.style.borderBottomRightRadius = "0%"; // Show the suggestion box
          suggestionBox.style.padding = "10px";
          const url = /^[Aa]\d{2,}$/.test(searchTerm)
            ? `${
                process.env.REACT_APP_BACKEND_URI
              }/get_appname_suggestion_combined?applicationName=${encodeURIComponent(
                searchTerm
              )}`
            : `${
                process.env.REACT_APP_BACKEND_URI
              }/get_appname_suggestion_combined?applicationName=${encodeURIComponent(
                searchTerm
              )}`;
          fetchWithTimeout(url, {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          })
            .then((response) => response.json())
            .then((data) => {
              if (data != "") {
                suggestionBox.innerHTML = "";
                const maxSuggestions = 20;
                for (let i = 0; i < data.length && i < maxSuggestions; i++) {
                  const suggestionItem = document.createElement("div");
                  suggestionItem.classList.add("suggestion-item");
                  suggestionItem.textContent = data[i];
                  suggestionBox.style.padding = "0px";
                  suggestionItem.addEventListener("click", async function () {
                    setSearchbarName(data[i]);
                    searchBox.style.borderBottomLeftRadius = "5px";
                    searchBox.style.borderBottomRightRadius = "5px";
                    suggestionBox.style.display = "none";

                    if (/^[Aa]\d{2,}$/.test(searchTerm)) {
                      await fetchAdditionalAppData(data[i]);
                      setSuggestionSelected(true);
                      if (arrowStatus) {
                        setSelectedSuggestion(true);
                      }
                    } else {
                      setSelectedSuggestion(true);
                      setSuggestionSelected(true);
                      setapplicationName1(data[i]);
                      setSearchbarName(data[i]);
                    }
                  });
                  suggestionBox.appendChild(suggestionItem);
                }
                if (data.length > 0 && searchTerm.length >= 3) {
                  suggestionBox.style.display = "block";
                  searchBox.style.borderBottomLeftRadius = "0%";
                  searchBox.style.borderBottomRightRadius = "0%";
                  setSelectedSuggestion(false);
                } else {
                  suggestionBox.style.display = "none";
                  searchBox.style.borderBottomLeftRadius = "5px";
                  searchBox.style.borderBottomRightRadius = "5px";
                }
              } else {
                setSelectedSuggestion(false);
                suggestionBox.innerHTML = "No results found";
                suggestionBox.style.display = "block";
                suggestionBox.style.padding = "10px";
                searchBox.style.borderBottomLeftRadius = "0%";
                searchBox.style.borderBottomRightRadius = "0%";
              }
            })
            .catch((error) => {
              console.error("Error fetching data:", error);
            });
        } else {
          suggestionBox.style.display = "none";
          searchBox.style.borderBottomLeftRadius = "5px";
          searchBox.style.borderBottomRightRadius = "5px";
        }
      }, 500);
      return () => clearTimeout(getApplicationSuggestion);
    }
  }, [applicationName1]);

  const handleSuggestionAppIdNames = async (selectedAppId, suggestionBox) => {
    await fetchAdditionalAppData(selectedAppId);
  };

  const fetchAdditionalAppData = async (selectedItem) => {
    const searchBox = document.getElementById("application-name");
    const suggestionBox = document.getElementById("suggestion-box");
    suggestionBox.innerHTML = "Loading Applications...";
    suggestionBox.style.display = "block";
    searchBox.style.borderBottomLeftRadius = "0%";
    searchBox.style.borderBottomRightRadius = "0%";
    suggestionBox.style.padding = "10px";
    try {
      const response = await fetchWithTimeout(
        `${
          process.env.REACT_APP_BACKEND_URI
        }/get_appname_appid_suggestion?applicationID=${encodeURIComponent(
          selectedItem
        )}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      const data = await response.json();
      suggestionBox.innerHTML = "";

      if (data.length > 0) {
        suggestionBox.style.display = "block";
        searchBox.style.borderBottomLeftRadius = "0%";
        searchBox.style.borderBottomRightRadius = "0%";
        suggestionBox.style.padding = "0px";

        for (let i = 0; i < data.length; i++) {
          const innerSuggestionItem = document.createElement("div");
          innerSuggestionItem.classList.add("suggestion-item");
          innerSuggestionItem.textContent = data[i];

          innerSuggestionItem.addEventListener("click", async function () {
            setSearchbarName(`${selectedItem} - ${data[i]}`);
            setapplicationName1(data[i]);
            setSuggestionSelected(true);
            setSelectedSuggestion(true);
            setArrowStatus(true);
            searchBox.style.borderBottomLeftRadius = "5px";
            searchBox.style.borderBottomRightRadius = "5px";
            suggestionBox.style.display = "none";
          });
          suggestionBox.appendChild(innerSuggestionItem);
        }
      } else {
        suggestionBox.innerHTML = "";
        suggestionBox.style.display = "none";
        searchBox.style.borderBottomLeftRadius = "5px";
        searchBox.style.borderBottomRightRadius = "5px";
      }
    } catch (error) {
      console.error("Error fetching additional app data:", error);
    }
  };

  const handleEditClick = () => {
    setIsReadOnly(!isReadOnly);
    setupdatedData(data);
  };

  console.log(updateddata);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        marginTop: "50px",
        height: "85vh",
        minWidth: "100%",
        position: "fixed",
      }}
    >
      <div
        style={{
          display: "flex",
          position: "relative",
          marginLeft: "20px",
          marginRight: "20px",
        }}
      >
        <div
          className="search-form"
          style={{ marginTop: "30px", marginLeft: "30px", padding: "0px" }}
        >
          <form onSubmit={handleSearchSubmit}>
            <label htmlFor="application-name">
              {"Search for an Application:"}{" "}
            </label>
            <div
              style={{
                display: "inline-flex",
                width: "300px",
                flexDirection: "column",
                position: "relative",
              }}
            >
              <div
                style={{ display: "flex", alignItems: "center", width: "100%" }}
              >
                <input
                  style={{ width: "100%" }}
                  className="search-input"
                  type="text"
                  name="applicationName"
                  id="application-name"
                  onChange={handleapplicationNameChange}
                  value={searchbarName}
                  placeholder={"Application Name/ID"}
                  autoComplete="off"
                />

                <button
                  id="suggestion-button"
                  style={{
                    display: arrowStatus ? "block" : "none",
                    position: "absolute",
                    right: "0.5rem",
                    background: "none",
                    border: "none",
                    cursor: "pointer",
                    padding: "0",
                  }}
                  onClick={(event) => {
                    event.preventDefault();
                    const searchbarName =
                      document.getElementById("application-name").value;
                    const selectedAppId = searchbarName.substring(0, 5);
                    const suggestionBox =
                      document.getElementById("suggestion-box");

                    if (suggestionBox.style.display === "block") {
                      suggestionBox.style.display = "none";
                    } else {
                      suggestionBox.style.display = "block";
                      suggestionBox.innerHTML = "";
                      handleSuggestionAppIdNames(selectedAppId, suggestionBox);
                    }
                  }}
                >
                  <KeyboardArrowDownIcon
                    style={{
                      fontSize: "1.5rem",
                      color: "gray",
                    }}
                  />
                </button>
              </div>

              <div className="suggestion-container">
                <div
                  style={{ width: "295px", position: "absolute" }}
                  id="suggestion-box"
                  className="suggestion-box"
                ></div>
              </div>
            </div>
            <input
              type="submit"
              style={{ backgroundColor: "#42B0D5" }}
              value="Search"
              ref={inputRef}
            ></input>
          </form>
        </div>
      </div>

      {loading && (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            position: "relative",
            justifyContent: "center",
            alignItems: "center",
            marginLeft: "20px",
            marginRight: "20px",
            height: "100%",
            overflowY: "auto",
            flexWrap: "wrap",
          }}
        >
          <div className="spinner"></div>
          <br></br>
          <p>Loading Application Data...</p>
        </div>
      )}

      {!(Object.keys(data).length === 0) && (
        <div
          style={{
            display: "flex",
            position: "relative",
            justifyContent: "center",
            alignItems: "center",
            marginLeft: "20px",
            marginRight: "20px",
            height: "100%",
            overflowY: "auto",
            flexWrap: "wrap",
          }}
        >
          <div
            style={{
              borderRadius: "5px",
              boxShadow: "0px 2px 5px rgba(0, 0, 0, 0.1)",
              backgroundColor: "#f5f5f5",
            }}
          >
            <div className="server-info">
              <div className="server-info-container">
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <span
                    className="server-info-title"
                    style={{ fontSize: "0.5 rem" }}
                  >
                    General Information
                  </span>
                  {isciadmingrp && (
                    <div>
                      {!isReadOnly && (
                        <Button
                          variant="contained"
                          sx={{
                            backgroundColor: "#0073AB",
                            margin: "0 10px",
                            "&:hover": {
                              transform: "translateY(-1px)",
                              boxShadow: 3,
                              backgroundColor: "#00243D",
                            },
                          }}
                          size="small"
                          onClick={async () => {
                            await toast.promise(handleApplyChanges(), {
                              pending: "Applying changes...",
                              error: "Error applying changes",
                            });
                          }}
                        >
                          Apply changes
                        </Button>
                      )}
                      <Button
                        variant="contained"
                        sx={{
                          backgroundColor: isReadOnly ? "#0073AB" : "#FA6A55",
                          margin: "0 10px",
                          "&:hover": {
                            transform: "translateY(-1px)",
                            boxShadow: 3,
                            backgroundColor: isReadOnly ? "#00243D" : "#FA381C",
                          },
                        }}
                        size="small"
                        onClick={handleEditClick}
                      >
                        {isReadOnly ? "Edit" : "Cancel"}
                      </Button>
                    </div>
                  )}
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    marginTop: "10px",
                    flexWrap: "wrap",
                  }}
                >
                  <div
                    className="server-info-column"
                    style={{ width: "680px" }}
                  >
                    {[
                      {
                        label: "Application ID",
                        value: data,
                        name: "u_application_id",
                        type: "text",
                        disabled: true,
                        important: true,
                      },
                      {
                        label: "Application Name",
                        value: isReadOnly ? data : updateddata,
                        name: "name",
                        type: "text",
                        disabled: isReadOnly,
                        important: true,
                      },
                      {
                        label: "Application Category",
                        value: isReadOnly ? data : updateddata,
                        name: "u_application_category",
                        type: "select",
                        options: {
                          "Business Application": "Business Application",
                          "Infrastructure Application":
                            "Infrastructure Application",
                          "Middleware Application": "Middleware Application",
                          Reporting: "Reporting",
                          unknown: "unknown",
                        },
                        disabled: isReadOnly,
                        important: true,
                      },

                      {
                        label: "Application Type",
                        value: isReadOnly ? data : updateddata,
                        name: "u_application_type",
                        type: "select",
                        options: {
                          "COTS(Commercial Off The Shelf)":
                            "COTS(Commercial Off The Shelf)",
                          Homegrown: "Bespoke",
                        },
                        disabled: isReadOnly,
                        important: true,
                      },
                      {
                        label: "Description",
                        value: isReadOnly ? data : updateddata,
                        name: "u_description",
                        type: "textarea",
                        disabled: isReadOnly,
                        important: true,
                      },

                      // {
                      //   label: "Service Classification",
                      //   value: isReadOnly ? data : updateddata,
                      //   name: "service_classification",
                      //   type: "select",
                      //   options: {
                      //     "Business Service": "Business Service",
                      //     "Technical Service": "Technical Service",
                      //     "Application Service": "Application Service",
                      //     "Service Offering": "Service Offering",
                      //     "Shared Service": "Shared Service",
                      //     "Billable Service": "Billable Service",
                      //   },
                      //   disabled: isReadOnly,

                      // },
                    ].map((item, index) => (
                      <div key={index} className="server-info-item">
                        <p className="server-info-label">
                          {item.label}
                          {item?.important && (
                            <span
                              style={{
                                color: "red",
                                marginLeft: "3px",
                              }}
                            >
                              &#42;
                            </span>
                          )}
                        </p>

                        <DynamicInput
                          type={item.type}
                          name={item.name}
                          options={item.options}
                          value={item.value}
                          disabled={item.disabled}
                          originaldata={data}
                          style={{
                            borderRadius: "8px",
                            border: "1px solid #ccc",
                            padding: "5px",
                            width: "60%",

                            textAlign: "center",
                            fontSize: "1rem",
                          }}
                          onChange={setupdatedData}
                        />
                      </div>
                    ))}
                  </div>
                  <div
                    className="server-info-column"
                    style={{ width: "680px", marginRight: "10px" }}
                  >
                    {[
                      {
                        label: "Operational Status",
                        value: isReadOnly ? data : updateddata,
                        name: "operational_status",
                        type: "select",
                        options: {
                          Operational: "1",
                          "Not Live": "2",
                          Retired: "6",
                        },
                        disabled: isReadOnly,
                        important: true,
                      },
                      {
                        label: "Environment",
                        value: isReadOnly ? data : updateddata,
                        name: "u_environment",
                        type: "text",
                        options: {
                          Production: "Production",
                          "Pre-Production": "Pre-Production",
                          Test: "Test",
                          Development: "Development",
                          "Education/Training": "Education/Training",
                          Unknown: "Unknown",
                        },
                        disabled: true,
                        important: true,
                      },
                      {
                        label: "Application Hosting Environment",
                        value: isReadOnly ? data : updateddata,
                        name: "u_application_hosting_env",
                        type: "select",
                        options: {
                          "On-Premises (Edge/Local)":
                            "On-Premises (Edge/Local)",
                          "Virtual Machines (IaaS)": "Virtual Machines (IaaS)",
                          "Kubernetes Cloud Service":
                            "Kubernetes Cloud Service",
                          "Containerized Cloud Service (PaaS)":
                            "Containerized Cloud Service (PaaS)",
                          "Non-containerized Cloud Service (PaaS)":
                            "Non-containerized Cloud Service (PaaS)",
                          "Consumption Based Cloud Service (Functions)":
                            "Consumption Based Cloud Service (Functions)",
                          "Data Database (PaaS)": "Data Database (PaaS)",
                          "Data Events (PaaS": "Data Events (PaaS)",
                          SaaS: "SaaS",
                        },
                        disabled: isReadOnly,
                        important: true,
                      },
                      {
                        label: "Short Description",
                        value: isReadOnly ? data : updateddata,
                        name: "short_description",
                        type: "textarea",
                        disabled: isReadOnly,
                        important: true,
                      },
                    ].map((item, index) => (
                      <div key={index} className="server-info-item">
                        <p className="server-info-label">
                          {item.label}
                          {item?.important && (
                            <span
                              style={{
                                color: "red",
                                marginLeft: "3px",
                              }}
                            >
                              &#42;
                            </span>
                          )}
                        </p>
                        <DynamicInput
                          type={item.type}
                          options={item.options}
                          name={item.name}
                          value={item.value}
                          disabled={item.disabled}
                          originaldata={data}
                          style={{
                            borderRadius: "8px",
                            border: "1px solid #ccc",
                            padding: "5px",
                            width: "60%",
                            textAlign: "center",
                            fontSize: "1rem",
                          }}
                          onChange={setupdatedData}
                        />
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
            <div className="server-info">
              <div className="server-info-container">
                <span
                  className="server-info-title"
                  style={{ fontSize: "0.5 rem" }}
                >
                  Context Data
                </span>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    marginTop: "10px",
                    flexWrap: "wrap",
                  }}
                >
                  <div
                    className="server-info-column"
                    style={{ width: "680px" }}
                  >
                    <div className="server-info-item">
                      <p className="server-info-label">
                        Owning Business Unit
                        <span
                          style={{
                            color: "red",
                            marginLeft: "3px",
                          }}
                        >
                          &#42;
                        </span>
                      </p>
                      <OwningBusinessUnitInput
                        type="suggestions"
                        name="u_owning_business_unit"
                        value={isReadOnly ? data : updateddata}
                        originaldata={data}
                        disabled={isReadOnly}
                        style={{
                          borderRadius: "8px",
                          border: "1px solid #ccc",
                          padding: "5px",
                          width: "60%",
                          textAlign: "center",
                          fontSize: "1rem",
                          backgroundColor: isReadOnly
                            ? "rgba(211, 211, 211, 1)"
                            : "white",
                        }}
                        onChange={setupdatedData}
                      />
                    </div>

                    {[
                      {
                        label: "Business Criticality",
                        value: isReadOnly ? data : updateddata,
                        name: "busines_criticality",
                        type: "select",
                        options: {
                          "1 - most critical": "1 - most critical",
                          "2 - somewhat critical": "2 - somewhat critical",
                          "3 - less critical": "3 - less critical",
                          "4 - not critical": "4 - not critical",
                          "Yet to be assessed": "Yet to be assessed",
                        },
                        disabled: isReadOnly,
                        important: true,
                      },
                      {
                        label: "Business Platform",
                        value: isReadOnly ? data : updateddata,
                        name: "u_business_platform",
                        type: "text",
                        disabled: true,
                      },
                      {
                        label: "Platform Portfolio",
                        value: isReadOnly ? data : updateddata,
                        name: "u_platform_portfolio",
                        type: "text",
                        disabled: true,
                      },
                    ].map((item, index) => (
                      <div key={index} className="server-info-item">
                        <p className="server-info-label">
                          {item.label}

                          {item?.important && (
                            <span
                              style={{
                                color: "red",
                                marginLeft: "3px",
                              }}
                            >
                              &#42;
                            </span>
                          )}
                        </p>
                        <DynamicInput
                          type={item.type}
                          options={item.options}
                          name={item.name}
                          value={item.value}
                          originaldata={data}
                          disabled={item.disabled}
                          style={{
                            borderRadius: "8px",
                            border: "1px solid #ccc",
                            padding: "5px",
                            width: "60%",
                            textAlign: "center",
                            fontSize: "1rem",
                          }}
                          onChange={setupdatedData}
                        />
                      </div>
                    ))}
                    {[
                      {
                        label: "Approval Group",
                        value: isReadOnly ? data : updateddata,
                        name: "change_control",
                        type: "suggestions",
                        important: true,
                      },
                    ].map((item, index) => (
                      <div key={index} className="server-info-item">
                        <p className="server-info-label">
                          {item.label}
                          {item?.important && (
                            <span
                              style={{
                                color: "red",
                                marginLeft: "3px",
                              }}
                            >
                              &#42;
                            </span>
                          )}
                        </p>
                        <DynamicInputSupportGroup
                          group_type="approval_group"
                          type={item.type}
                          options={item.options}
                          name={item.name}
                          value={item.value}
                          originaldata={data}
                          disabled={isReadOnly}
                          style={{
                            borderRadius: "8px",
                            border: "1px solid #ccc",
                            padding: "5px",
                            width: "60%",
                            backgroundColor: isReadOnly
                              ? "rgba(211, 211, 211, 1)"
                              : "white",
                            textAlign: "center",
                            fontSize: "1rem",
                          }}
                          onChange={setupdatedData}
                        />
                      </div>
                    ))}
                  </div>
                  <div
                    className="server-info-column"
                    style={{ width: "680px", marginRight: "10px" }}
                  >
                    {[
                      {
                        label: "Support Group",
                        value: isReadOnly ? data : updateddata,
                        name: "support_group",
                        type: "suggestions",
                        important: true,
                      },
                      {
                        label: "Support Group L2",
                        value: isReadOnly ? data : updateddata,
                        name: "u_support_group_l2",
                        type: "suggestions",
                      },
                      {
                        label: "Support Group L3",
                        value: isReadOnly ? data : updateddata,
                        name: "u_support_group_l3",
                        type: "suggestions",
                      },
                      {
                        label: "Support Group L4",
                        value: isReadOnly ? data : updateddata,
                        name: "u_support_group_l4",
                        type: "suggestions",
                      },
                      {
                        label: "Service Request Support Group",
                        value: isReadOnly ? data : updateddata,
                        name: "u_service_request_support_group",
                        type: "suggestions",
                      },
                    ].map((item, index) => (
                      <div key={index} className="server-info-item">
                        <p className="server-info-label">
                          {item.label}
                          {item?.important && (
                            <span
                              style={{
                                color: "red",
                                marginLeft: "3px",
                              }}
                            >
                              &#42;
                            </span>
                          )}
                        </p>
                        <DynamicInputSupportGroup
                          group_type="support_group"
                          type={item.type}
                          name={item.name}
                          value={item.value}
                          disabled={isReadOnly}
                          originaldata={data}
                          style={{
                            borderRadius: "8px",
                            border: "1px solid #ccc",
                            padding: "5px",
                            width: "60%",
                            backgroundColor: isReadOnly
                              ? "rgba(211, 211, 211, 1)"
                              : "white",
                            textAlign: "center",
                            fontSize: "1rem",
                          }}
                          onChange={setupdatedData}
                        />
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
            <div className="server-info">
              <div className="server-info-container">
                <span
                  className="server-info-title"
                  style={{ fontSize: "0.5 rem" }}
                >
                  Ownership
                </span>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    marginTop: "10px",
                    flexWrap: "wrap",
                  }}
                >
                  <div
                    className="server-info-column"
                    style={{ width: "680px" }}
                  >
                    {[
                      {
                        label: "Engineering Director",
                        value: isReadOnly ? data : updateddata,
                        name: "u_engineering_director",
                        type: "suggestions",
                      },
                      {
                        label: "Senior Engineering Manager",
                        value: isReadOnly ? data : updateddata,
                        name: "u_sr_engineering_manager",
                        type: "suggestions",
                      },
                      {
                        label: "Engineering Manager",
                        value: isReadOnly ? data : updateddata,
                        name: "u_engineering_manager",
                        type: "suggestions",
                      },
                    ].map((item, index) => (
                      <div key={index} className="server-info-item">
                        <p className="server-info-label">{item.label}</p>
                        <DynamicInputOwnership
                          type={item.type}
                          name={item.name}
                          value={item.value}
                          originaldata={data}
                          disabled={isReadOnly}
                          style={{
                            borderRadius: "8px",
                            border: "1px solid #ccc",
                            padding: "5px",
                            width: "60%",
                            backgroundColor: isReadOnly
                              ? "rgba(211, 211, 211, 1)"
                              : "white",
                            textAlign: "center",
                            fontSize: "1rem",
                          }}
                          onChange={setupdatedData}
                        />
                      </div>
                    ))}
                  </div>
                  <div
                    className="server-info-column"
                    style={{ width: "680px", marginRight: "10px" }}
                  >
                    {[
                      {
                        label: "Senior Service Owner",
                        value: isReadOnly ? data : updateddata,
                        name: "managed_by",
                        type: "suggestions",
                        important: true,
                      },
                      {
                        label: "Service Owner",
                        value: isReadOnly ? data : updateddata,
                        name: "owned_by",
                        type: "suggestions",
                        important: true,
                      },
                    ].map((item, index) => (
                      <div key={index} className="server-info-item">
                        <p className="server-info-label">
                          {item.label}
                          {item?.important && (
                            <span
                              style={{
                                color: "red",
                                marginLeft: "3px",
                              }}
                            >
                              &#42;
                            </span>
                          )}
                        </p>
                        <DynamicInputOwnership
                          type={item.type}
                          name={item.name}
                          originaldata={data}
                          value={item.value}
                          disabled={isReadOnly}
                          style={{
                            borderRadius: "8px",
                            border: "1px solid #ccc",
                            padding: "5px",
                            width: "60%",
                            backgroundColor: isReadOnly
                              ? "rgba(211, 211, 211, 1)"
                              : "white",
                            textAlign: "center",
                            fontSize: "1rem",
                          }}
                          onChange={setupdatedData}
                        />
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
            <div className="server-info">
              <div className="server-info-container">
                <span
                  className="server-info-title"
                  style={{ fontSize: "0.5 rem" }}
                >
                  Other Information
                </span>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    marginTop: "10px",
                    flexWrap: "wrap",
                  }}
                >
                  <div
                    className="server-info-column"
                    style={{ width: "680px" }}
                  >
                    <div className="server-info-item">
                      <p className="server-info-label">Location</p>
                      <LocationInput
                        type="suggestions"
                        name="location"
                        value={isReadOnly ? data : updateddata}
                        originaldata={data}
                        disabled={isReadOnly}
                        style={{
                          borderRadius: "8px",
                          border: "1px solid #ccc",
                          padding: "5px",
                          width: "60%",
                          backgroundColor: isReadOnly
                            ? "rgba(211, 211, 211, 1)"
                            : "white",
                          textAlign: "center",
                          fontSize: "1rem",
                        }}
                        onChange={setupdatedData}
                      />
                    </div>

                    {[
                      // { label: 'Sys ID', value: data, name:'sys_id' },
                      {
                        label: "Licensing Type",
                        value: isReadOnly ? data : updateddata,
                        name: "u_licensing_type",
                        type: "select",
                        options: {
                          Rented: "Rented",
                          Perpetual: "Perpetual",
                        },
                      },
                    ].map((item, index) => (
                      <div key={index} className="server-info-item">
                        <p className="server-info-label">{item.label}</p>
                        <DynamicInput
                          type={item.type}
                          options={item.options}
                          name={item.name}
                          value={item.value}
                          originaldata={data}
                          disabled={isReadOnly}
                          style={{
                            borderRadius: "8px",
                            border: "1px solid #ccc",
                            padding: "5px",
                            width: "60%",
                            backgroundColor: isReadOnly
                              ? "rgba(211, 211, 211, 1)"
                              : "white",
                            textAlign: "center",
                            fontSize: "1rem",
                          }}
                          onChange={setupdatedData}
                        />
                      </div>
                    ))}
                    <div className="server-info-item">
                      <p className="server-info-label">MVC</p>
                      <Tags
                        name="u_mvc"
                        value={isReadOnly ? data : updateddata}
                        originaldata={data}
                        disabled={true}
                        style={{
                          borderRadius: "8px",
                          border: "1px solid #ccc",
                          padding: "5px",
                          width: "60%",
                          backgroundColor: isReadOnly
                            ? "rgba(211, 211, 211, 1)"
                            : "white",
                          textAlign: "center",
                          fontSize: "1rem",
                        }}
                        onChange={setupdatedData}
                      />
                    </div>
                  </div>
                  <div
                    className="server-info-column"
                    style={{ width: "680px", marginRight: "10px" }}
                  >
                    {[
                      {
                        label: "Used By Region",
                        value: isReadOnly ? data : updateddata,
                        name: "u_used_by_region",
                        type: "suggestions",
                      },
                    ].map((item, index) => (
                      <div key={index} className="server-info-item">
                        <p className="server-info-label">{item.label}</p>
                        <Tags2
                          type="text"
                          name={item.name}
                          value={item.value}
                          disabled={isReadOnly}
                          originaldata={data}
                          style={{
                            borderRadius: "8px",
                            border: "1px solid #ccc",
                            padding: "5px",
                            width: "60%",
                            backgroundColor: isReadOnly
                              ? "rgba(211, 211, 211, 1)"
                              : "white",
                            textAlign: "center",
                            fontSize: "1rem",
                          }}
                          onChange={setupdatedData}
                        />
                      </div>
                    ))}
                    <div className="server-info-item">
                      <p className="server-info-label">
                        Used By Business Unit
                        <span
                          style={{
                            color: "red",
                            marginLeft: "3px",
                          }}
                        >
                          &#42;
                        </span>
                      </p>
                      <Tags1
                        type="text"
                        name="u_used_by_business_unit"
                        value={isReadOnly ? data : updateddata}
                        disabled={isReadOnly}
                        originaldata={data}
                        style={{
                          borderRadius: "8px",
                          border: "1px solid #ccc",
                          padding: "5px",
                          width: "60%",
                          backgroundColor: isReadOnly
                            ? "rgba(211, 211, 211, 1)"
                            : "white",
                          textAlign: "center",
                          fontSize: "1rem",
                        }}
                        onChange={setupdatedData}
                      />
                    </div>
                    <div className="server-info-item">
                      <p className="server-info-label">Vendor</p>
                      <VendorInput
                        type="suggestions"
                        name="vendor"
                        value={isReadOnly ? data : updateddata}
                        disabled={isReadOnly}
                        originaldata={data}
                        style={{
                          borderRadius: "8px",
                          border: "1px solid #ccc",
                          padding: "5px",
                          width: "60%",
                          backgroundColor: isReadOnly
                            ? "rgba(211, 211, 211, 1)"
                            : "white",
                          textAlign: "center",
                          fontSize: "1rem",
                        }}
                        onChange={setupdatedData}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default CIOnboarding;
